define("lh4/services/social-media/account-api", ["exports", "jquery", "lh4/config/environment", "lh4/enums/duration", "lh4/utils/cron", "moment", "lh4/services/social-media/facebook", "lh4/services/social-media/twitter"], function (_exports, _jquery, _environment, _duration, _cron, _moment, _facebook, _twitter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var LOCAL_STORAGE_SOCIAL_MEDIA_REDIRECT_ORIGIN = 'social-media-redirect-origin';
  /**
   * Adjust localDates based on the requested from date
   *
   * @param {Object} post - post response object
   * @param {string} [post.scheduleRepeat] - cron expression
   * @param {string} [post.scheduleTime] - schedule timestamp
   * @param {string} [from] - from date
   * @returns {Moment|Date} - adjusted moment date object
   */

  function adjustLocalDate(_ref, from) {
    var scheduleRepeat = _ref.scheduleRepeat,
        scheduleTime = _ref.scheduleTime;

    var localDate = _moment.default.tz(scheduleTime, _moment.default.tz.guess());

    if (!scheduleRepeat || (0, _moment.default)(from) <= (0, _moment.default)()) {
      return localDate;
    }

    var frequencyRecurrence = (0, _cron.calculateFrequency)(scheduleRepeat);
    var fromDate = (0, _moment.default)(from);
    return (0, _cron.getFutureRecurrenceTime)(localDate, fromDate, frequencyRecurrence);
  }
  /**
   * Adjust Post response object
   *
   * @param {Object} post - post response object
   * @param {string} [from] - from date
   * @returns {Object} response with adjustments
   */


  function adjustScheduledPostResponse(postResponse, from) {
    var post = postResponse;

    if (!post.scheduleTime) {
      post.scheduleTime = post.scheduleRepeatNextTime;
    }

    var adjustedLocalDate = adjustLocalDate(post, from);
    post.scheduleTime = adjustedLocalDate.toDate();
    post.publishTimeDisplayTime = adjustedLocalDate.format('hh:mmA');
    post.publishTimeDisplayDate = adjustedLocalDate.format('MMMM Do, YYYY');
    return post;
  }
  /**
   * Create graphData from twitter posts.
   *
   * non-enterprise twitter API does not provide separate metrics endpoint like fb
   * fb locks us in into using two separate endpoints for posts and metrics because of separate cursors
   * @param {Array<Object>} posts - tweets
   * @returns {Object} Object with arrays for each of past 7 days with total metrics per day
   */


  function toTwitterAnalytics(posts) {
    var graphData = {
      likes: [],
      shares: []
    };
    var startDate = parseInt((0, _moment.default)().subtract(6, 'day').startOf('day').format('x'), 10); // twitter returns past week + today

    for (var i = 0; i <= 7; i += 1) {
      graphData.likes.push({
        time: startDate + _duration.default.DAY.IN_MILLISECONDS * i,
        value: 0
      });
      graphData.shares.push({
        time: startDate + _duration.default.DAY.IN_MILLISECONDS * i,
        value: 0
      });
    }

    posts.forEach(function (post) {
      var time = parseInt((0, _moment.default)(post.publishTime).startOf('day').format('x'), 10);
      var likes = graphData.likes.find(function (data) {
        return data.time === time;
      });
      var shares = graphData.shares.find(function (data) {
        return data.time === time;
      });

      if (likes) {
        likes.value += post.analytics.likes;
      }

      if (shares) {
        shares.value += post.analytics.shares;
      }
    });
    return graphData;
  }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    facebook: Ember.inject.service('social-media/facebook'),
    twitter: Ember.inject.service('social-media/twitter'),

    /**
     * Load posts for account
     * @param {number} accountId - social media account ID
     * @param {Object} [dateRange] - cursor of specific page to load
     * @param {string} [dateRange.from] - from date
     * @param {string} [dateRange.to] - to date
     * @param {number} limit - page size
     * @returns {Promise<{posts: Array<Object>, meta: {pagination: Object}}>} - pageable posts
     */
    loadScheduledPosts: function loadScheduledPosts(accountId, dateRange) {
      var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
      var params = {
        limit: limit,
        from: _moment.default.tz(dateRange.from, 'YYYY-MM-DD', _moment.default.tz.guess()).startOf('day').format(),
        to: _moment.default.tz(dateRange.to, 'YYYY-MM-DD', _moment.default.tz.guess()).endOf('day').format(),
        statuses: ['PENDING', 'ERROR']
      };

      var qs = _jquery.default.param(params);

      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/posts/scheduled?").concat(qs)).then(function (response) {
        response.posts = response.posts.map(function (post) {
          return adjustScheduledPostResponse(post, params.from);
        }).sort(function (left, right) {
          return (0, _moment.default)(left.scheduleTime).diff((0, _moment.default)(right.scheduleTime));
        });
        return response;
      });
    },
    loadScheduledPost: function loadScheduledPost(accountId, postId) {
      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/posts/scheduled/").concat(postId)).then(function (response) {
        response.post = adjustScheduledPostResponse(response.post);
        return response;
      });
    },
    schedulePost: function schedulePost(accountId, post) {
      var scheduleOnce = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (!scheduleOnce) {
        Ember.set(post, 'scheduleRepeatStart', post.scheduleTime);
      }

      var adjustments = scheduleOnce ? {} : {
        scheduleTime: null
      };
      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/posts"), {
        type: 'POST',
        data: JSON.stringify(_objectSpread(_objectSpread({}, post), adjustments))
      });
    },
    updateScheduledPost: function updateScheduledPost(accountId, post) {
      var scheduleOnce = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (!scheduleOnce) {
        Ember.set(post, 'scheduleRepeatStart', post.scheduleTime);
      }

      var adjustments = scheduleOnce ? {} : {
        scheduleTime: null
      };
      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/posts/scheduled/").concat(post.id), {
        type: 'PUT',
        data: JSON.stringify(_objectSpread(_objectSpread({}, post), adjustments))
      });
    },
    deleteScheduledPost: function deleteScheduledPost(accountId, postId) {
      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/posts/scheduled/").concat(postId), {
        type: 'DELETE'
      });
    },
    uploadImage: function uploadImage(accountId, fullBlob) {
      var formData = new FormData();
      formData.append('photo', fullBlob, "".concat(this.get('model.id'), "-full.png"));
      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/posts/images"), {
        method: 'POST',
        data: formData,
        contentType: false,
        processData: false
      }).then(function (response) {
        return response.url;
      });
    },
    deleteImage: function deleteImage(accountId, post) {
      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/posts/images"), {
        type: 'DELETE',
        data: JSON.stringify({
          uri: post.picture1Uri
        })
      });
    },

    /**
     * Load posts for account
     * @param {number} accountId - social media account ID
     * @param {Object} [dateRange] - cursor of specific page to load
     * @param {string} [dateRange.from] - from date
     * @param {string} [dateRange.to] - to date
     * @param {number} limit - page size
     * @param {string} [cursor] - cursor of specific page to load
     * @returns {Promise<{posts: Array<Object>, meta: {pagination: Object}}>} - pageable posts
     */
    loadPosts: function loadPosts(accountId) {
      var dateRange = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        from: null,
        to: null
      };
      var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
      var cursor = arguments.length > 3 ? arguments[3] : undefined;

      var qs = _jquery.default.param({
        limit: limit,
        from: dateRange.from,
        to: dateRange.to,
        after: cursor
      });

      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/posts?").concat(qs)).then(function (response) {
        response.posts.forEach(function (aPost) {
          var post = aPost;

          var localDate = _moment.default.tz(post.publishTime, _moment.default.tz.guess());

          post.publishTimeDisplayTime = localDate.format('hh:mmA');
          post.publishTimeDisplayDate = localDate.format('MMMM Do, YYYY');
          return post;
        });
        response.meta.pagination.from = dateRange.from;
        response.meta.pagination.to = dateRange.to;
        return response;
      });
    },
    loadAnalytics: function loadAnalytics(accountId) {
      var dateRange = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        from: null,
        to: null
      };
      var cursor = arguments.length > 2 ? arguments[2] : undefined;

      var qs = _jquery.default.param({
        from: dateRange.from,
        to: dateRange.to,
        after: cursor
      });

      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/analytics?").concat(qs));
    },
    loadAnalyticsHelper: function loadAnalyticsHelper(account, dateRange) {
      var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;
      var accountType = account.get('type');
      var postData = this.loadPosts(account.get('id'), dateRange, limit);

      switch (accountType) {
        case _facebook.ACCOUNT_TYPE_FB_PAGE:
          return Ember.RSVP.hash({
            postData: postData,
            graphData: this.loadAnalytics(account.get('id'), dateRange)
          });

        case _twitter.ACCOUNT_TYPE_TWITTER:
          return Ember.RSVP.resolve(postData).then(function (pData) {
            return {
              postData: pData,
              graphData: {
                data: toTwitterAnalytics(pData.posts)
              }
            };
          });

        default:
          return false;
      }
    },

    /**
     * Save origin from where user is being redirected to Social Media provider
     * @param {number} accountId - account id, used to prevent other accounts from redirecting to wrong location
     * @param {number} [expirationMS] - time in ms when this should be ignored, default 5 minutes
     */
    setRedirectOrigin: function setRedirectOrigin(accountId) {
      var expirationMS = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5 * _duration.default.MINUTE.IN_MILLISECONDS;
      var redirectOrigin = {
        origin: window.location.href,
        accountId: accountId,
        timestamp: new Date().getTime() + expirationMS
      };
      window.localStorage.setItem(LOCAL_STORAGE_SOCIAL_MEDIA_REDIRECT_ORIGIN, JSON.stringify(redirectOrigin));
    },

    /**
     * get redirect origin if there's any
     * @param {number} accountId - account id, used to prevent other accounts from redirecting to wrong location
     * @returns {boolean|string} false if not present or expired. String with full uri otherwise
     */
    getRedirectOrigin: function getRedirectOrigin(accountId) {
      var redirectDestination = false;

      if (accountId) {
        var redirectOrigin = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_SOCIAL_MEDIA_REDIRECT_ORIGIN)); // check if it exists and now is before expiration

        if (redirectOrigin && new Date().getTime() < redirectOrigin.timestamp) {
          redirectDestination = redirectOrigin.origin;
        }

        window.localStorage.removeItem(LOCAL_STORAGE_SOCIAL_MEDIA_REDIRECT_ORIGIN);
      }

      return redirectDestination;
    },
    checkRedirectErrors: function checkRedirectErrors() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var isSocialMediaRedirectDenied = queryParams.error || queryParams.denied;

      if (!isSocialMediaRedirectDenied) {
        return {};
      }

      var errorMessage = 'Something went wrong';

      if (queryParams.error) {
        // facebook returns 'error'

        /* eslint-disable camelcase */
        errorMessage = (queryParams === null || queryParams === void 0 ? void 0 : queryParams.error_reason) === 'user_denied' ? 'Access Denied by User' : queryParams === null || queryParams === void 0 ? void 0 : queryParams.error_description;
        /* eslint-enable camelcase */
      } else if (queryParams.denied) {
        // twitter returns 'denied'
        errorMessage = 'Access Denied by User';
      }

      var redirectOrigin = this.getRedirectOrigin(queryParams.accountId);

      if (redirectOrigin) {
        return {
          errorMessage: errorMessage,
          redirectOrigin: redirectOrigin
        };
      }

      return {
        errorMessage: errorMessage
      };
    },
    adjustRedirectUrlHash: function adjustRedirectUrlHash(location) {
      if (_environment.default.locationType !== 'hash') {
        return null;
      }

      var isFBRedirect = location.search.includes('code');
      var isTwitterRedirect = location.search.includes('oauth_token');

      if (isFBRedirect) {
        return this.facebook.adjustRedirectUrlHash(location);
      }

      if (isTwitterRedirect) {
        return this.twitter.adjustRedirectUrlHash(location);
      }

      return false;
    }
  });

  _exports.default = _default;
});