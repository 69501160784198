define("lh4/controllers/authenticated/customer-database", ["exports", "jquery", "lh4/config/environment", "lh4/controllers/general-list-controller"], function (_exports, _jquery, _environment, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var buildUrl = function buildUrl(format) {
    return Ember.computed('model.location.id', 'model.location.product', function getter() {
      var path;

      if (this.get('model.location.product') === 'echo-pro') {
        path = 'echo-pro';
      } else if (this.can.can('manage processing/customer')) {
        path = 'processing';
      }

      var query = _jquery.default.param({
        locations: [this.get('model.location.id')],
        token: this.session.get('data.authenticated').token
      });

      return "".concat(_environment.default.host, "/api/v1/reports/").concat(path, "/").concat(format, "/customer-directory?").concat(query);
    });
  };

  var _default = _generalListController.default.extend({
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    queryParams: ['search', 'offset', 'limit', 'order', 'sort'],
    order: 'firstName,lastName',
    csvURL: buildUrl('csv'),
    xlsURL: buildUrl('xls'),
    pdfURL: buildUrl('pdf'),
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super((_this$location = this.location) === null || _this$location === void 0 ? void 0 : (_this$location$model = _this$location.model) === null || _this$location$model === void 0 ? void 0 : _this$location$model.id);
    }),
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      },
      importContacts: function importContacts() {
        this.transitionToRoute('authenticated.customer-database-import');
      },
      filterChanged: function filterChanged(filter) {
        var limit = filter.limit,
            offset = filter.offset,
            sortAsc = filter.sortAsc,
            order = filter.sortBy;
        var sort = sortAsc ? 'asc' : 'desc';
        this.setProperties({
          limit: limit,
          offset: offset,
          sort: sort,
          order: order
        });
      },
      trackReportDownload: function trackReportDownload(type) {
        var product;

        if (this.get('model.location.product') === 'echo-pro') {
          product = 'echo-pro';
        } else if (this.can.can('manage processing/customer')) {
          product = 'processing';
        }

        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'reports',
          eventAction: 'download',
          eventLabel: "".concat(product, " - customer-directory - ").concat(type)
        });
      }
    }
  });

  _exports.default = _default;
});