define("lh4/controllers/authenticated/loyalty/programs/create", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    // when creating a program the user will always be the owner
    isUserLoyaltyProgramOwner: true,
    isEditing: false,
    location: Ember.inject.service()
  });

  _exports.default = _default;
});