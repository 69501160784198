define("lh4/components/epro/manage-employees/job-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    loginTypeOptions: [{
      name: 'Counter Service',
      value: 0
    }, {
      name: 'Table Service',
      value: 1
    }, {
      name: 'Host Mode',
      value: 2
    }, {
      name: 'Time Clock',
      value: 3
    }],
    operateAsOptions: [{
      name: 'Cashier Mode',
      value: 0
    }, {
      name: 'Manager Mode',
      value: 1
    }, {
      name: 'Manager Mode (Tips Only)',
      value: 3
    }, {
      name: 'Restricted Mode',
      value: 2
    }],
    menuTypeOptions: [{
      name: 'Departments',
      value: 'Departments'
    }, {
      name: 'Tags',
      value: 'Tags'
    }, {
      name: 'Favorites',
      value: 'Favorites'
    }],
    sendActionOptions: [{
      name: 'Send & Stay',
      value: 'Stay'
    }, {
      name: 'Send & Continue (System Default)',
      value: 'Continue'
    }, {
      name: 'Send & New',
      value: 'New'
    }, {
      name: 'Send & Logout',
      value: 'Logout'
    }],
    orderTypeOptions: Ember.computed.map('orderTypes', function (_ref) {
      var name = _ref.name,
          value = _ref.id,
          isActive = _ref.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    printerOptions: Ember.computed.map('printers', function (_ref2) {
      var name = _ref2.name,
          value = _ref2.guid,
          isActive = _ref2.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    settingOptions: Ember.computed('location.model.id', function getter() {
      var options = [{
        description: 'Allows a user to access host mode',
        id: 'isHostModeEnabled',
        name: 'Access Host Mode'
      }, {
        description: 'Allows a user to view and open recently completed tickets',
        id: 'canAccessRecentlyClosedTicket',
        name: 'Access Completed Tickets'
      }, {
        description: 'Job has access to all tickets',
        id: 'isAccessAllTicketsEnabled',
        name: 'Access to All Tickets'
      }, {
        description: 'Allows a user to use the fast pay buttons',
        id: 'isFastPayEnabled',
        name: 'Allow Fast Pay'
      }, {
        description: 'Allows a user to see the ticket grid view and create tickets',
        id: 'isGridViewEnabled',
        name: 'Allow Grid View'
      }, {
        description: 'Job has access to manually inputting weight for items sold by weight',
        id: 'isManualWeightEntryEnabled',
        name: 'Allow Manual Weight Entry'
      }, {
        description: 'Generates a Guest Check that contains customer information when a user transfers a ticket that' + ' has a customer attached to it to another user',
        id: 'shouldAutoPrintDeliveryInformationOnTransfer',
        name: 'Auto Print Delivery Information on Transfer'
      }, {
        description: 'Prevents a user from seeing any sales or payment information that happens with their cash' + ' drawer or personal till',
        id: 'isBlind',
        name: 'Blind Job'
      }, {
        description: 'Requires a user to declare their cash tips before clocking out',
        id: 'isDeclareTipsAtClockoutEnabled',
        name: 'Declare Tips at Clock Out'
      }, {
        description: 'Allows a user to hold kitchen items on a ticket',
        id: 'isHoldItemsEnabled',
        name: 'Hold Kitchen Items'
      }, {
        description: 'Allows a user to ignore required modifier sets on a menu item',
        id: 'isIgnoreRequiredModifiersEnabled',
        name: 'Ignore Required Modifier Sets'
      }, {
        description: 'Allows a user to modify the dollar limit threshold of a customer\'s house account',
        id: 'canSetHouseAccountThreshold',
        name: 'Set House Account Threshold'
      }, {
        description: 'Allows a user to scan a barcode on a receipt to transfer a ticket to themselves',
        id: 'isTransferTicketOnScanEnabled',
        name: 'Transfer Tickets on Scan'
      }, {
        description: 'Allows a user to use a personal till',
        id: 'usesPersonalTill',
        name: 'Use Personal Till'
      }, {
        description: 'Allows a user to view or print their shift report',
        id: 'isDisplayShiftReportEnabled',
        name: 'View/Print Shift Report'
      }];
      var hiddenDepartmentsOption = {
        description: 'Allows users with this permission to view any Department currently hidden due to Menu Availability configurations',
        id: 'canViewHiddenDepartments',
        name: 'View Hidden Departments'
      };
      var insertIndex = options.findIndex(function (option) {
        return option.id === 'usesPersonalTill';
      }) + 1;

      if (this.can.can('view menu availability in echo-pro/menu-availability')) {
        options.splice(insertIndex, 0, hiddenDepartmentsOption);
      }

      return options;
    }),
    actions: {
      changeProperty: function changeProperty(record, value, property) {
        record.set(property, value.toUpperCase());
      }
    }
  });

  _exports.default = _default;
});