define("lh4/components/dashboard/ad-banner", ["exports", "ember-data", "moment", "lh4/config/environment", "lh4/enums/country-code"], function (_exports, _emberData, _moment, _environment, _countryCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    media: Ember.inject.service(),
    session: Ember.inject.service(),
    location: Ember.inject.service(),
    classNames: ['ad-banner'],
    request: {
      ads: []
    },
    current: 0,
    right: Ember.computed('current', function getter() {
      return Ember.String.htmlSafe("right: calc(".concat(this.current, " * 100%);"));
    }),
    currentCountryCode: Ember.computed('location.model.countryCode', function getter() {
      return this.get('location.model.countryCode') || _countryCode.default.US;
    }),
    showRedirect: false,
    adBannerUrl: undefined,
    target: '_blank',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.adBannerUrl) {
        var promise = this.ajax.request(this.adBannerUrl).then(function (response) {
          response.ads.forEach(function (anAd) {
            var ad = anAd; // eslint thinks this should be a template literal, but there isn't
            // a great way to do that from the JSON response and it's also
            // not clear if there might be other replacements in other places.
            // seems best route is to have eslint ignore this rule "violation"
            // eslint-disable-next-line no-template-curly-in-string

            ad.link = ad.link.replace('${locationId}', _this.location.model.id);
          });
          return response;
        });
        this.set('request', _emberData.default.PromiseObject.create({
          promise: promise
        }));
      }
    },
    validAds: Ember.computed.filter('request.ads', function callback(_ref) {
      var active = _ref.active,
          start = _ref.start,
          end = _ref.end,
          requiredProducts = _ref.requiredProducts,
          requiredBrands = _ref.requiredBrands,
          requiredSicCodes = _ref.requiredSicCodes,
          countryCode = _ref.countryCode;

      if (!active) {
        return false;
      }

      if (!this.isVisibleForCountry(countryCode, this.currentCountryCode)) {
        return false;
      }

      if (Array.isArray(requiredBrands) && !requiredBrands.includes(_environment.default.branding.name)) {
        return false;
      }

      if (Array.isArray(requiredProducts) && !requiredProducts.includes(this.location.model.product)) {
        return false;
      }

      if (Array.isArray(requiredSicCodes) && !requiredSicCodes.includes(this.location.model.sicCode)) {
        return false;
      } // start and end are optional, but if included we'll want
      // to check that we are after start and before the end


      var isStartValid = !start || (0, _moment.default)().isAfter(start);
      var isEndValid = !end || (0, _moment.default)().isBefore(end);
      return isStartValid && isEndValid;
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.request.then(function () {
        Ember.run.next(_this2, function () {
          var _this2$validAds$lengt, _this2$validAds;

          var max = (_this2$validAds$lengt = (_this2$validAds = _this2.validAds) === null || _this2$validAds === void 0 ? void 0 : _this2$validAds.length) !== null && _this2$validAds$lengt !== void 0 ? _this2$validAds$lengt : 0;

          if (max > 1) {
            _this2.set('runner', _this2.tick(max));
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      if (this.runner) {
        Ember.run.cancel(this.runner);
      }
    },
    tick: function tick(max) {
      var _this3 = this;

      var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
      return Ember.run.later(this, function () {
        var index = _this3.current;
        index += 1;

        if (index === max) {
          index = 0;
        }

        _this3.set('current', index); // recurse


        _this3.set('runner', _this3.tick(max));
      }, duration * 1000);
    },
    isVisibleForCountry: function isVisibleForCountry(countryCode, currentCountryCode) {
      // if no specific country is assigned, it's visible
      if (!countryCode) {
        return true;
      } // if a single country is assigned and it matches the current country, it's visible


      if (typeof countryCode === 'string' && countryCode === currentCountryCode) {
        return true;
      } // if multiple countries are assigned and the current country is in the list, it's visible


      if (Array.isArray(countryCode) && countryCode.includes(currentCountryCode)) {
        return true;
      } // otherwise, it's not visible


      return false;
    },
    actions: {
      close: function close() {
        this.session.set('data.adBannerClosed', true);

        if (this.runner) {
          Ember.run.cancel(this.runner);
        }
      },
      selectAd: function selectAd() {
        // cancel the current tick and create a new one
        // that will pick up in 30 seconds
        if (this.runner) {
          var _this$validAds;

          Ember.run.cancel(this.runner);
          this.set('runner', this.tick((_this$validAds = this.validAds) === null || _this$validAds === void 0 ? void 0 : _this$validAds.length, 30));
        }
      }
    }
  });

  _exports.default = _default;
});