define("lh4/mirage/factories/epro-employee", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    locationId: 1,
    name: function name() {
      return _faker.default.name.firstName();
    },
    dateOfBirth: '',
    hireDate: '1/1/1111',
    loginPin: '1234',
    magswipeData: '112222223',
    email: function email() {
      return _faker.default.internet.email();
    },
    addressLine1: function addressLine1() {
      return _faker.default.address.streetAddress();
    },
    addressLine2: '',
    city: function city() {
      return _faker.default.address.city();
    },
    state: function state() {
      return _faker.default.address.state();
    },
    zipCode: function zipCode() {
      return _faker.default.address.zipCode();
    },
    payrollCode: '34234243',
    lookup: 'string',
    isLaborTrackingEnabled: true,
    isRecordingBreaks: true,
    isPaidHourly: true,
    isMasterAccount: true,
    healthCardExpDate: '1/11/1111',
    liquorCardExpDate: '1/11/1111',
    isActive: true,
    isCardOnlyAccess: true,
    lastLoggedInAt: '1/11/1111',
    currentLoginDate: '1/11/1111',
    jobs: [{
      canAccessRecentlyClosedTicket: true,
      createdAt: '2016-05-26T13:26:36.429Z',
      defaultOrderTypeGuid: '4-40-e0807dca-6ce0-4f6a-aa4e-239617084b9a',
      description: 'Test a description',
      guid: function guid() {
        return _faker.default.datatype.uuid();
      },
      isAccessAllTicketsEnabled: true,
      isActive: true,
      isAllowBreaksEnabled: true,
      isBlind: false,
      isCashManaging: true,
      isCompleteAllTicketsEnabled: false,
      isDeclareTipsAtClockoutEnabled: false,
      isDisplayShiftReportEnabled: true,
      isFastPayEnabled: false,
      isGridViewEnabled: true,
      isHideUnadjustedTicketsEnabled: true,
      isHoldItemsEnabled: true,
      isHostModeEnabled: true,
      isIgnoreRequiredModifiersEnabled: false,
      isOverrideRestrictionsEnabled: false,
      isPrintOnTransferEnabled: false,
      isPrintShiftReportEnabled: true,
      isTemplate: false,
      isTrackTimeEnabled: true,
      isTransferTicketOnScanEnabled: true,
      isViewTicketStatusEnabled: false,
      locationId: 1,
      loginType: 2,
      name: 'Manager',
      operatesAs: 'Cashier',
      ordinal: null,
      overtimePayRate: '27.00',
      payRate: '18.00',
      shouldAutoPrintDeliveryInformationOnTransfer: false,
      ticketPaymentOperationType: 0,
      updatedAt: '2017-03-31T17:07:06.659Z',
      usesPersonalTill: true,
      defaultSendAction: 'New'
    }, {
      canAccessRecentlyClosedTicket: true,
      createdAt: '2016-05-26T13:26:36.429Z',
      defaultOrderTypeGuid: '4-40-e0807dca-6ce0-4f6a-aa4e-239617084b9a',
      description: 'Test a description',
      guid: function guid() {
        return _faker.default.datatype.uuid();
      },
      isAccessAllTicketsEnabled: true,
      isActive: true,
      isAllowBreaksEnabled: true,
      isBlind: false,
      isCashManaging: true,
      isCompleteAllTicketsEnabled: false,
      isDeclareTipsAtClockoutEnabled: false,
      isDisplayShiftReportEnabled: true,
      isFastPayEnabled: false,
      isGridViewEnabled: true,
      isHideUnadjustedTicketsEnabled: true,
      isHoldItemsEnabled: true,
      isHostModeEnabled: true,
      isIgnoreRequiredModifiersEnabled: false,
      isOverrideRestrictionsEnabled: false,
      isPrintOnTransferEnabled: false,
      isPrintShiftReportEnabled: true,
      isTemplate: false,
      isTrackTimeEnabled: true,
      isTransferTicketOnScanEnabled: true,
      isViewTicketStatusEnabled: false,
      locationId: 1,
      loginType: 2,
      name: 'Manager',
      operatesAs: 'Cashier',
      ordinal: null,
      overtimePayRate: '27.00',
      payRate: '18.00',
      shouldAutoPrintDeliveryInformationOnTransfer: false,
      ticketPaymentOperationType: 0,
      updatedAt: '2017-03-31T17:07:06.659Z',
      usesPersonalTill: true,
      defaultSendAction: 'Logout'
    }, {
      canAccessRecentlyClosedTicket: true,
      createdAt: '2016-05-26T13:26:36.429Z',
      defaultOrderTypeGuid: '4-40-e0807dca-6ce0-4f6a-aa4e-239617084b9a',
      description: 'Test a description',
      guid: function guid() {
        return _faker.default.datatype.uuid();
      },
      isAccessAllTicketsEnabled: true,
      isActive: true,
      isAllowBreaksEnabled: true,
      isBlind: false,
      isCashManaging: true,
      isCompleteAllTicketsEnabled: false,
      isDeclareTipsAtClockoutEnabled: false,
      isDisplayShiftReportEnabled: true,
      isFastPayEnabled: false,
      isGridViewEnabled: true,
      isHideUnadjustedTicketsEnabled: true,
      isHoldItemsEnabled: true,
      isHostModeEnabled: true,
      isIgnoreRequiredModifiersEnabled: false,
      isOverrideRestrictionsEnabled: false,
      isPrintOnTransferEnabled: false,
      isPrintShiftReportEnabled: true,
      isTemplate: false,
      isTrackTimeEnabled: true,
      isTransferTicketOnScanEnabled: true,
      isViewTicketStatusEnabled: false,
      locationId: 1,
      loginType: 2,
      name: 'Manager',
      operatesAs: 'Cashier',
      ordinal: null,
      overtimePayRate: '27.00',
      payRate: '18.00',
      shouldAutoPrintDeliveryInformationOnTransfer: false,
      ticketPaymentOperationType: 0,
      updatedAt: '2017-03-31T17:07:06.659Z',
      usesPersonalTill: true,
      defaultSendAction: 'Continue'
    }, {
      canAccessRecentlyClosedTicket: true,
      createdAt: '2016-05-26T13:26:36.429Z',
      defaultOrderTypeGuid: '4-40-e0807dca-6ce0-4f6a-aa4e-239617084b9a',
      description: 'Test a description',
      guid: function guid() {
        return _faker.default.datatype.uuid();
      },
      isAccessAllTicketsEnabled: true,
      isActive: true,
      isAllowBreaksEnabled: true,
      isBlind: false,
      isCashManaging: true,
      isCompleteAllTicketsEnabled: false,
      isDeclareTipsAtClockoutEnabled: false,
      isDisplayShiftReportEnabled: true,
      isFastPayEnabled: false,
      isGridViewEnabled: true,
      isHideUnadjustedTicketsEnabled: true,
      isHoldItemsEnabled: true,
      isHostModeEnabled: true,
      isIgnoreRequiredModifiersEnabled: false,
      isOverrideRestrictionsEnabled: false,
      isPrintOnTransferEnabled: false,
      isPrintShiftReportEnabled: true,
      isTemplate: false,
      isTrackTimeEnabled: true,
      isTransferTicketOnScanEnabled: true,
      isViewTicketStatusEnabled: false,
      locationId: 1,
      loginType: 2,
      name: 'Manager',
      operatesAs: 'Cashier',
      ordinal: null,
      overtimePayRate: '27.00',
      payRate: '18.00',
      shouldAutoPrintDeliveryInformationOnTransfer: false,
      ticketPaymentOperationType: 0,
      updatedAt: '2017-03-31T17:07:06.659Z',
      usesPersonalTill: true,
      defaultSendAction: 'New'
    }],
    permissions: [{
      id: '1',
      createdAt: '2016-03-31T21:40:12.000Z',
      description: 'Edit Customers',
      guid: function guid() {
        return _faker.default.datatype.uuid();
      },
      type: 1,
      updatedAt: '2016-04-11T16:12:12.000Z'
    }]
  });

  _exports.default = _default;
});