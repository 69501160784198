define("lh4/controllers/application", ["exports", "lh4/config/environment", "lh4/enums/microfrontend-event-keys"], function (_exports, _environment, _microfrontendEventKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var testing = Ember.testing;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    location: Ember.inject.service(),
    userIdle: Ember.inject.service(),
    userActivity: Ember.inject.service(),
    session: Ember.inject.service(),
    logger: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    metrics: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    isMicrofrontend: Ember.computed('location.model.id', function getter() {
      return Boolean(this.appShell);
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // initialize logger service to catch console errors


      this.get('logger');
      this.userActivity.on('userActive', function () {
        if (!testing && _this.session.isAuthenticated) {
          _this.session.set('data.lastActiveAt', Date.now());
        }
      });
      this.userIdle.on('idleChanged', function (isIdle) {
        var idle = isIdle && Date.now() - _this.userIdle.IDLE_TIMEOUT >= _this.session.data.lastActiveAt;

        if (!testing && idle && _this.session.isAuthenticated) {
          _this.session.invalidate();
        }
      }); // This needs to be set here since the authenticated controller doesnt always reliably run certain
      // code in the Single-SPA setup

      if (this.appShell) {
        this.appShell.subscribe(_microfrontendEventKeys.default.SIGNOUT, function () {
          _this.metrics.trackEvent(['datadogRum'], {
            event: 'Click Sign Out'
          });

          _this.metrics.invoke('logout', 'datadogRum', {});

          _this.session.invalidate();
        });
      }
    },
    destroy: function destroy() {
      if (this.appShell) {
        this.appShell.unsubscribe(_microfrontendEventKeys.default.SIGNOUT);
      }
    }
  });

  _exports.default = _default;
});