define("lh4/mixins/routes/locations/location/echo-pro/employee-schedule/shift/edit-route-mixin", ["exports", "moment", "lh4/mixins/authenticated-route-mixin"], function (_exports, _moment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    tokenData: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    queryParams: {
      day: {
        refreshModel: true
      },
      employeeId: {
        refreshModel: true
      },
      isSourceCalendar: {
        refreshModel: true
      }
    },
    modelInternal: function modelInternal(params, employees, location) {
      return this.store.createRecord('echo-pro/employee-shift', {
        startsAt: (0, _moment.default)(params.day).startOf('day').add(8, 'hour').toDate(),
        endsAt: (0, _moment.default)(params.day).startOf('day').add(17, 'hour').toDate(),
        employee: employees.find(function (e) {
          return e.get('id') === params.employeeId;
        }),
        locationId: location.id
      });
    },
    model: function model(params) {
      var location = this.modelFor('authenticated.locations.location');

      var _this$modelFor = this.modelFor('authenticated.locations.location.echo-pro.employee-schedule'),
          employees = _this$modelFor.employees;

      return Ember.RSVP.hash({
        employees: employees,
        preferences: this.store.findRecord('preferences', this.get('tokenData.data.sub')),
        day: (0, _moment.default)(params.day).toDate(),
        shift: this.modelInternal(params, employees, location),
        jobs: this.store.query('echo-pro/job', {
          location: location.id
        })
      });
    }
  });

  _exports.default = _default;
});