define("lh4/routes/authenticated/epro/menu/price-schedule-groups/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    location: Ember.inject.service(),
    modelName: 'priceScheduleGroup',
    childChangesets: {
      priceSchedules: 'priceScheduleChangesets'
    },
    model: function model() {
      var locationId = this.location.model.id;
      var priceScheduleGroup = this.store.createRecord('echo-pro/price-schedule-group', {
        locationId: locationId
      });
      var priceSchedule = this.store.createRecord('echo-pro/price-schedule', {
        locationId: locationId,
        name: 'Schedule 1',
        createdAt: new Date()
      });
      priceScheduleGroup.priceSchedules.addObject(priceSchedule);
      return Ember.RSVP.hash({
        priceScheduleGroup: priceScheduleGroup
      });
    }
  });

  _exports.default = _default;
});