define("lh4/routes/authenticated/internet-payments/settings/business-settings", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, _lighthousePermissionsMixin.default, {
    ajax: Ember.inject.service(),
    requiredPermissions: ['view business settings nav in general'],
    model: function model() {
      var batchAutoSettings = this.ajax.request("/api/v2/business-settings/".concat(this.location.model.id, "/batch-auto-close")).then(function (result) {
        return result;
      }).catch(function () {
        return {
          autoClose: undefined,
          autoCloseDays: undefined
        };
      });
      return Ember.RSVP.hash({
        batchAutoSettings: batchAutoSettings
      });
    },
    setupController: function setupController() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super.apply(this, args);

      var controller = args[0];
      controller.setup();
    }
  });

  _exports.default = _default;
});