define("lh4/controllers/authenticated/locations/location/echo-pro/employee-schedule/publish/email", ["exports", "lh4/classes/locations/location/echo-pro/employee-schedule/publish/employee-with-editable-email", "lh4/mixins/edit-controller-mixin"], function (_exports, _employeeWithEditableEmail, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {
    ajax: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    location: Ember.inject.service(),
    columns: ['Name', 'Job', 'Email'],
    employeesWithoutEmailCount: Ember.computed('employees.@each.hasEmail', function getter() {
      return this.get('employees').filter(function (e) {
        return !e.get('hasEmail');
      }).length;
    }),
    employees: Ember.computed('model.employees', function getter() {
      return (this.get('model.employees') || []).map(function (e) {
        return _employeeWithEditableEmail.default.create({
          content: e
        });
      });
    }),
    actions: {
      enableEmailEdit: function enableEmailEdit(employee) {
        employee.set('editEnabled', true);
      },
      saveEmployee: function saveEmployee(proxy) {
        var _this = this;

        var employee = proxy.get('content');
        proxy.set('isSaving', true);
        this.editRecord.validateAndSave(employee).then(function () {
          return proxy.setProperties({
            editEnabled: false,
            hasEmail: true
          });
        }).catch(function (validationMessages) {
          return _this.flashMessagesHelper.pushMessages(validationMessages, 'negative');
        }).finally(function () {
          return proxy.set('isSaving', false);
        });
      },
      cancelEmployeeEditing: function cancelEmployeeEditing(proxy) {
        proxy.set('editEnabled', false);
      },
      send: function send() {
        var _this2 = this;

        var location = this.get('model.location');
        var employees = this.get('selected').map(function (e) {
          return e.get('id');
        }).join(',');

        var _this$get = this.get('model.range'),
            start = _this$get.from,
            end = _this$get.to;

        this.set('isLoading', true);
        var url = "/api/v1/echo-pro/schedules/email?location=".concat(location.id, "&employees=").concat(employees) + "&start=".concat(start.toISOString(), "&end=").concat(end.toISOString());
        return this.ajax.request(url, {
          method: 'POST'
        }).then(function () {
          return _this2.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
        }).catch(function (e) {
          return _this2.flashMessagesHelper.pushMessages(_this2.responseMessages.getResponseErrors(e));
        }).finally(function () {
          return _this2.set('isLoading', false);
        });
      },
      clear: function clear() {
        this.get('model.employees').forEach(function (e) {
          return e.rollbackAttributes();
        });
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule'); // setting checked inputs to false

        this.set('selected', false);
      }
    }
  });

  _exports.default = _default;
});