define("lh4/templates/authenticated/epro/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wbO+s8pG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"menuViewerPage\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"menu-viewer-button-container\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary basic button \",[28,\"if\",[[24,[\"isLoading\"]],\"loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"navigateToMenuViewerPage\"]],[8],[0,\"\\n\\t\\t\\t\\tVIEW MENU\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"title-bar-menu\"],[8],[0,\"\\n\\t\"],[5,\"ui/tab-elements\",[[12,\"class\",\"standard\"]],[[\"@routes\"],[[23,0,[\"routeData\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/epro/menu.hbs"
    }
  });

  _exports.default = _default;
});