define("lh4/routes/authenticated/epro/menu/price-groups/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    location: Ember.inject.service(),
    modelName: 'corporateGroup',
    model: function model() {
      return Ember.RSVP.hash({
        corporateGroup: this.store.createRecord('corporate-group', {
          corpId: this.location.model.corpId
        }),
        corporateGroupList: this.store.query('corporate-group', {
          corporation: this.location.model.corpId
        })
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      controller.set('isSaving', false);
    }
  });

  _exports.default = _default;
});