define("lh4/utils/group-and-format-consecutive-days", ["exports", "lh4/constants/weekdays", "moment"], function (_exports, _weekdays, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupAndFormatConsecutiveDays;

  /**
   * Groups and formats consecutive days with the same start and end times.
   *
   * @function groupAndFormatConsecutiveDays
   * @param {Array} hours - An array of objects representing: startTime, endTime, dayOfWeek, isOvernight.
   * @returns {Array} - An array of formatted strings representing grouped and formatted hours.
   *
   * @example
   *
   * param: [
   *   { dayOfWeek: 'MONDAY', startTime: '08:00:00', endTime: '17:00:00', isOvernight: boolean },
   *   { dayOfWeek: 'TUESDAY', startTime: '08:00:00', endTime: '17:00:00', isOvernight: boolean },
   *   { dayOfWeek: 'WEDNESDAY', startTime: '08:00:00', endTime: '17:00:00', isOvernight: boolean },
   *   { dayOfWeek: 'FRIDAY', startTime: '08:00:00', endTime: '17:00:00', isOvernight: boolean },
   * ];
   *
   * result: ['Mon to Wed 8:00am-5:00pm', 'Fri 8:00am-5:00pm']
   */
  function groupAndFormatConsecutiveDays(hours) {
    function formatTime(time) {
      return (0, _moment.default)(time, 'HH:mm:ss').format('h:mma');
    }

    var result = [];
    var group = [hours[0]];

    for (var i = 1; i <= hours.length; i += 1) {
      var current = hours[i];
      var prev = group[group.length - 1];
      var prevDay = _weekdays.DAY_OF_WEEK_ORDER[prev === null || prev === void 0 ? void 0 : prev.dayOfWeek];
      var currentDay = current ? _weekdays.DAY_OF_WEEK_ORDER[current.dayOfWeek] : null;

      if (current && currentDay - prevDay === 1 && current.startTime === prev.startTime && current.endTime === prev.endTime) {
        group.push(current);
      } else {
        var days = group.length === 1 ? _weekdays.ABBREVIATIONS[group[0].dayOfWeek] : "".concat(_weekdays.ABBREVIATIONS[group[0].dayOfWeek], " to ").concat(_weekdays.ABBREVIATIONS[group[group.length - 1].dayOfWeek]);
        var formattedHours = "".concat(days, " ").concat(formatTime(group[0].startTime), "-").concat(formatTime(group[0].endTime));
        result.push(formattedHours);
        group = current ? [current] : [];
      }
    }

    return result;
  }
});