define("lh4/routes/authenticated/loyalty/programs/edit", ["exports", "lh4/routes/general-edit-route", "lh4/config/environment"], function (_exports, _generalEditRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'program',
    permissions: Ember.inject.service(),
    location: Ember.inject.service(),
    // This clear custom model function was created in order to clear locationIds
    // After API gets a saving error and tries to rollback the model, locationIds relationships are not
    // reset and stay in the model, that is why custom locationIds reset was added.
    clearCustomModel: function clearCustomModel(model, modelProperty) {
      var programModel = model[modelProperty];

      if (!programModel) {
        return;
      }

      if (programModel.isNew) {
        programModel.unloadRecord();
      } else {
        Ember.run.later(function () {
          programModel.rollbackAttributes();
          programModel.set('locationIds', []);
        }, 2000);
      }
    },
    model: function model(_ref) {
      var _this = this;

      var programId = _ref.programId;
      return Ember.RSVP.hash({
        program: this.store.findRecord('loyalty/program', programId),
        locations: this.store.query('location', {}).then(function (locations) {
          return locations.filter(function (location) {
            return _this.permissions.hasProduct('echo-pro', location);
          }).filter(function (_ref2) {
            var features = _ref2.features;

            if (_environment.default.respectLocationFeatureFlags) {
              return features === null || features === void 0 ? void 0 : features.loyaltyv1;
            }

            return true;
          }).map(function (_ref3) {
            var _loyaltyProgram$get, _loyaltyProgram$get2;

            var id = _ref3.id,
                name = _ref3.name,
                loyaltyProgram = _ref3.loyaltyProgram;
            var belongsToProgram = !!(loyaltyProgram !== null && loyaltyProgram !== void 0 && (_loyaltyProgram$get = loyaltyProgram.get) !== null && _loyaltyProgram$get !== void 0 && _loyaltyProgram$get.call(loyaltyProgram, 'id'));
            var isSameProgram = !!(belongsToProgram && (loyaltyProgram === null || loyaltyProgram === void 0 ? void 0 : (_loyaltyProgram$get2 = loyaltyProgram.get) === null || _loyaltyProgram$get2 === void 0 ? void 0 : _loyaltyProgram$get2.call(loyaltyProgram, 'id')) === programId);
            var belongsButNotSameProgram = belongsToProgram && !isSameProgram;
            return {
              id: id,
              name: belongsButNotSameProgram ? "".concat(name, " (belongs to ").concat(loyaltyProgram.get('name'), ")") : name,
              isCheckboxDisabled: belongsButNotSameProgram
            };
          });
        }),
        programs: this.store.query('loyalty/program', {
          limit: 'all'
        }),
        discounts: this.store.query('loyalty/discount', {
          loyaltyProgramGuids: [programId]
        })
      });
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
      // that doesn't exist in the loyalty program context because they are not location dependent
    }
  });

  _exports.default = _default;
});