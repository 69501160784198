define("lh4/controllers/authenticated/epro/menu/dynamic-pricing/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    isAnyScheduleOverlapping: false,
    validateAndSaveChangesets: function validateAndSaveChangesets(changesets, key) {
      var _this = this;

      return changesets.map(function (record) {
        return record.validate().then(function () {
          if (!record.isValid && !record.get('isVoided')) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.set('changeset', _this.model.dynamicPrice); // record.save was throwing because models don't have a save endpoint

          record.execute();
          record.rollback();

          _this.model.dynamicPrice.get(key).pushObject(record.data);

          return true;
        }).catch(function () {
          _this.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    actions: {
      update: function update() {
        var _this2 = this;

        if (this.isSaving) {
          return;
        }

        this.set('showErrors', true);
        this.set('isSaving', true);
        this.clearServerError();
        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.dynamicPrice.schedules, this.changeset.scheduleChangesets);
        var schedulePromises = [];

        if (this.changeset.get('timeOption') === 'Custom Schedule') {
          schedulePromises = this.validateAndSaveChangesets(this.changeset.scheduleChangesets, 'schedules');
        } else {
          // Rolling back Custom Schedule values because user already selected Day parts and pressed Save button
          // If this is not done - child changeset will stay dirty
          // and prevent closing edit form after updated record was already saved
          this.changeset.scheduleChangesets.forEach(function (record) {
            record.rollback();
          });
        }

        Ember.RSVP.all(schedulePromises).then(function () {
          _this2.changeset.validate().then(function () {
            if (_this2.changeset.isInvalid || _this2.areChildrenInvalid) {
              return Ember.RSVP.reject(_this2.changeset.errors);
            }

            if (isPristine) {
              _this2.clearTemporaryData();

              _this2.closeTray();

              return Ember.RSVP.resolve();
            }

            var snapshot = _this2.changeset.snapshot();

            return _this2.changeset.save().then(function () {
              _this2.clearTemporaryData();

              _this2.closeTray();
            }).catch(function (_ref) {
              var errors = _ref.errors;

              if (!(errors && errors[0])) {
                return;
              }

              _this2.changeset.restore(snapshot);

              _this2.changeset.data.rollbackAttributes(); // TODO normalize how we handle these error messages


              var updateError = "Something went wrong, ".concat(_this2.instanceName, " could not be updated.");
              errors.forEach(function (error) {
                var errorMessage = error.details && error.details[0] || error.message || updateError;

                _this2.set('serverError', errorMessage);

                _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                  _this2.set('serverError', null);
                }, _this2.serverErrorTimeoutDelay));
              });
            });
          }).catch(function (errors) {
            // We currently don't have a field component for checkboxes
            // Because of this there is no standard way of displaying
            // error messages for boolean fields
            // https://jira.shift4payments.com/browse/LH-8853
            if (errors.length) {
              var booleanError = errors.find(function (error) {
                return typeof error.value === 'boolean';
              });

              if (booleanError) {
                _this2.set('booleanError', booleanError.validation);

                _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                  _this2.set('booleanError', null);
                }, _this2.serverErrorTimeoutDelay));
              } else {
                // Used to catch custom validation error that validates models one to another
                // For unique name
                var error = errors[0];

                if (error && error.validation) {
                  _this2.set('customError', error.validation);
                }
              }
            }
          }).finally(function () {
            return _this2.set('isSaving', false);
          });
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      setScheduleValidation: function setScheduleValidation(isAnyScheduleOverlapping) {
        this.set('isAnyScheduleOverlapping', isAnyScheduleOverlapping);
      }
    }
  });

  _exports.default = _default;
});