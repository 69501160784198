define("lh4/mirage/factories/epro-job", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    locationId: 1,
    name: 'Test Job',
    description: '',
    payRate: 1.11,
    overtimePayRate: 2.22,
    loginType: 1,
    isOverrideRestrictionsEnabled: true,
    isTrackTimeEnabled: true,
    isDeclareTipsAtClockoutEnabled: true,
    isDisplayShiftReportEnabled: true,
    usesPersonalTill: true,
    isBlind: true,
    defaultOrderTypeGuid: function defaultOrderTypeGuid() {
      return _faker.default.datatype.uuid();
    },
    isFastPayEnabled: true,
    isGridViewEnabled: true,
    isCashManaging: true,
    isTransferTicketOnScanEnabled: true,
    isPrintOnTransferEnabled: true,
    isAccessAllTicketsEnabled: true,
    isHostModeEnabled: true,
    isCompleteAllTicketsEnabled: true,
    isHideUnadjustedTicketsEnabled: true,
    isHoldItemsEnabled: true,
    canAccessRecentlyClosedTicket: true,
    isIgnoreRequiredModifiersEnabled: true,
    isViewTicketStatusEnabled: true,
    shouldAutoPrintDeliveryInformationOnTransfer: true,
    ticketPaymentOperationType: 2,
    operatesAs: 'Cashier',
    ordinal: 0,
    isTemplate: true,
    isActive: true,
    defaultSendAction: 'Logout'
  });

  _exports.default = _default;
});