define("lh4/controllers/authenticated/locations/location/echo-pro/employee-schedule/template/create", ["exports", "lh4/mixins/edit-controller-mixin"], function (_exports, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {
    modelName: 'model.template',
    location: Ember.inject.service(),
    actions: {
      afterSaveHook: function afterSaveHook() {
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
      },
      clear: function clear() {
        this.get('model.template').rollbackAttributes();
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
      }
    }
  });

  _exports.default = _default;
});