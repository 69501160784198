define("lh4/routes/authenticated/loyalty/discounts/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'discount',
    location: Ember.inject.service(),
    model: function model(_ref) {
      var discountId = _ref.discountId;
      return Ember.RSVP.hash({
        discount: this.store.findRecord('loyalty/discount', discountId),
        programs: this.modelFor('authenticated.loyalty.discounts').programs
      });
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
      // that doesn't exist in the loyalty discount context because they are not location dependent
    }
  });

  _exports.default = _default;
});