define("lh4/models/echo-pro/employee-phone", ["exports", "ember-data", "ember-data/relationships", "ember-cp-validations", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberData, _relationships, _emberCpValidations, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.phoneTypes = _exports.default = void 0;
  var phoneTypes = [{
    name: 'Home',
    value: 0
  }, {
    name: 'Mobile',
    value: 1
  }, {
    name: 'Work',
    value: 2
  }, {
    name: 'Emergency',
    value: 3
  }, {
    name: 'Unknown',
    value: 4
  }];
  _exports.phoneTypes = phoneTypes;
  var Validations = (0, _emberCpValidations.buildValidations)({
    number: {
      description: 'Phone number',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('phone-number', {
        message: 'Phone number has incorrect format',
        disabled: Ember.computed('model.launchDarkly', function getter() {
          var _this$model$get;

          var canViewLBM2 = (_this$model$get = this.model.get('location')) === null || _this$model$get === void 0 ? void 0 : _this$model$get.canViewLBM2;

          var featureFlag = this.model.get('launchDarkly').featureFlags[_launchDarklyFeatureFlags.default.PHONE_NUMBER_IDENTIFY_CALLER];

          return !featureFlag || canViewLBM2;
        })
      })]
    },
    type: (0, _emberCpValidations.validator)(function (value) {
      return phoneTypes.some(function (type) {
        return type.value === value;
      }) || 'Incorrect Phone Type';
    })
  });

  var _default = _emberData.default.Model.extend(Validations, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service('location'),
    number: _emberData.default.attr('phone'),
    locationId: _emberData.default.attr('number'),
    type: _emberData.default.attr('number'),
    phoneTypes: phoneTypes,
    employee: (0, _relationships.belongsTo)('echo-pro/employee')
  });

  _exports.default = _default;
});