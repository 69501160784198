define("lh4/routes/authenticated/dashboard2", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/utils/sort-array-by-array", "lh4/utils/widget-is-usable", "lh4/utils/has-required-products", "lh4/components/dashboard-widgets/dashboard-widget"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin, _sortArrayByArray, _widgetIsUsable, _hasRequiredProducts, _dashboardWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    permissions: Ember.inject.service(),
    session: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    features: Ember.inject.service(),
    can: Ember.inject.service(),
    titleToken: 'Dashboard',
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments);

      return this.store.findRecord('preferences', this.get('tokenData.data.sub')).then(function (preferences) {
        if (preferences.get('oldDashboard')) {
          _this.transitionTo('authenticated.dashboard');
        }
      });
    },
    model: function model() {
      var _this2 = this;

      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations,
        preferences: this.store.peekRecord('preferences', this.get('tokenData.data.sub')),
        widgets: this.store.query('dashboard/widget', {
          locationId: this.location.model.id
        }),
        layouts: this.store.findAll('dashboard/layout').then(function (layouts) {
          return layouts.filter(function (layout) {
            return (0, _hasRequiredProducts.default)(layout.requiredProducts, _this2.location.model);
          });
        })
      });
    },
    afterModel: function afterModel(model) {
      if (!model.preferences.get('dashboardOrder')) {
        // find any widget that is usable by one of the user's locations
        var dashboardOrder = [];
        model.widgets.toArray().forEach(function (widget) {
          if ((0, _widgetIsUsable.default)(widget, model.locations)) {
            var slug = widget.slug;
            dashboardOrder.push({
              settings: _dashboardWidget.DEFAULT_WIDGET_SETTINGS[slug] || {},
              slug: slug
            });
          }
        });
        /**
         * Why:
         * 		Sorting is being done by using 'slug' value.
         * 		In order to show default widgets sorted by specific list we've to sort the widgets.
         *		If we've widget which isn't on the list - add it to the end of the array.
         */

        var sortedDashboardOrder = (0, _sortArrayByArray.default)(dashboardOrder, _dashboardWidget.DEFAULT_WIDGETS_SORT, 'slug');
        /**
         * Why:
         * 		After array sort we've only one 'sales' widget which is net-sales.
         * 		We've to show both 'net-sales' and 'gross-sales'.
         * 		To add 'gross-sales' we've to splice the array and put it in the place manually.
         */

        var salesIndex = sortedDashboardOrder.findIndex(function (widget) {
          return widget.slug === 'sales';
        });

        if (salesIndex !== -1) {
          sortedDashboardOrder.splice(salesIndex + 1, 0, _dashboardWidget.GROSS_SALES_WIDGET);
        }

        model.preferences.set('dashboardOrder', sortedDashboardOrder);
      }
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      controller.send('setLayout', controller.activeLayout);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          layoutMode: false,
          activeLayoutSlug: undefined
        });
      }
    },
    redirect: function redirect() {
      if (this.location.model.isCorpHq) {
        this.transitionTo('authenticated.location-settings');
      }

      if (this.can.cannot('view dashboard in general') && !this.location.model.isCorpHq) {
        if (this.can.cannot('access for us in general')) {
          this.transitionTo('authenticated.settings.account');
        } else {
          this.transitionTo('authenticated.locations.location.overview', this.location.model.id);
        }
      }
    }
  });

  _exports.default = _default;
});