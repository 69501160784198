define("lh4/routes/authenticated/epro/menu/price-groups/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    modelName: 'corporateGroup',
    model: function model(_ref) {
      var priceGroupId = _ref.priceGroupId;
      return Ember.RSVP.hash({
        corporateGroup: this.store.findRecord('corporate-group', priceGroupId, {
          adapterOptions: {
            corpId: this.location.model.corpId
          }
        })
      });
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
      // that doesn't exist in the price groups
    }
  });

  _exports.default = _default;
});