define("lh4/controllers/authenticated/locations/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    showLocationTabs: false,
    location: Ember.inject.service(),

    get routeData() {
      var routeData = [];

      if (!this.location.model.isCorpHq && this.can.can('access for us in general')) {
        routeData.push({
          name: 'authenticated.locations.location.overview',
          title: 'Overview'
        });
      }

      if (this.location.model.isCorpHq && (this.can.can('view day parts in day-part') || this.can.can('view revenue-centers in revenue-center'))) {
        routeData.push({
          name: 'authenticated.location-settings',
          title: 'Location Settings'
        });
      }

      if (this.can.can('view business settings in business-setting') && !this.location.model.isCorpHq) {
        routeData.push({
          name: 'authenticated.locations.location.business-settings',
          title: 'Business Settings'
        });
      }

      if (this.can.can('view day parts in day-part')) {
        routeData.push({
          name: 'authenticated.locations.location.day-parts',
          title: 'Day Parts'
        });
      }

      if (this.can.can('view revenue-centers in revenue-center')) {
        routeData.push({
          name: 'authenticated.locations.location.revenue-centers',
          title: 'Revenue Centers'
        });
      }

      if (this.can.can('view advantage program in advantage-program') && !this.location.model.isCorpHq) {
        routeData.push({
          name: 'authenticated.locations.location.advantage-program',
          title: 'Advantage Program'
        });
      }

      return routeData;
    }

  });

  _exports.default = _default;
});