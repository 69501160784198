define("lh4/routes/authenticated/epro/menu/modifier-sets/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    modelName: 'modifierSet',
    childChangesets: {
      modifiersMap: 'mapChangesets'
    },
    model: function model(_ref) {
      var modifierSetId = _ref.modifierSetId;
      return Ember.RSVP.hash({
        modifierSet: this.store.findRecord('echo-pro/modifier-set', modifierSetId),
        modifiers: this.modelFor('authenticated.epro.menu.modifier-sets').modifiers,
        items: this.modelFor('authenticated.epro.menu.modifier-sets').items
      });
    }
  });

  _exports.default = _default;
});