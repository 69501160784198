define("lh4/mixins/controllers/locations/location/echo-pro/employee-schedule/shift/edit-controller-mixin", ["exports", "moment", "lh4/mixins/edit-controller-mixin", "lh4/utils/day-to-week-range", "lh4/utils/period-to-days-array"], function (_exports, _moment, _editControllerMixin, _dayToWeekRange2, _periodToDaysArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_editControllerMixin.default, {
    modelName: 'model.shift',
    employeeSchedule: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    colors: ['white', 'green', 'violet', 'purple', 'teal', 'yellow', 'pink', 'blue', 'olive', 'red'],
    weekDays: Ember.computed('model.shift', function getter() {
      var day = this.get('model.shift.day');
      var formattedDate = (0, _moment.default)(day).format('ddd');
      var weekStart = this.get('model.preferences.weekStart');

      var _dayToWeekRange = (0, _dayToWeekRange2.default)(day, weekStart),
          from = _dayToWeekRange.from,
          to = _dayToWeekRange.to;

      var range = (0, _periodToDaysArray.default)(from, to);
      var weekDays = range.map(function (date, id) {
        return {
          id: id,
          value: (0, _moment.default)(date).format('ddd'),
          date: date
        };
      });
      this.set('selectedDays', [weekDays.find(function (d) {
        return d.value === formattedDate;
      })]);
      return weekDays;
    }),
    selectedDaysChange: Ember.observer('selectedDays.@each', function callback() {
      var selectedDays = this.get('selectedDays');

      if (!selectedDays) {
        return;
      }

      var currentDay = this.get('model.shift.day');
      selectedDays = selectedDays.sort(function (a, b) {
        return a.id - b.id;
      });

      if (selectedDays.length === 0 || (0, _moment.default)(selectedDays[0].date).isSame(currentDay)) {
        return;
      }

      this.set('model.shift.day', selectedDays[0].date);
    }),
    datesToClone: Ember.computed('selectedDays.@each', function getter() {
      return this.get('selectedDays').sort(function (a, b) {
        return a.id - b.id;
      }).slice(1).map(function (d) {
        return d.date;
      });
    }),
    getShiftsFromModel: function getShiftsFromModel() {
      var sourceShift = this.get('model.shift');
      return this.get('datesToClone').map(function (date) {
        return sourceShift.cloneWithDate(date);
      }).concat(sourceShift);
    },
    hasConflicts: function hasConflicts() {
      var shiftsToCheck = this.getShiftsFromModel();
      var employeeSchedule = this.employeeSchedule;
      return shiftsToCheck.some(function (shift) {
        return employeeSchedule.hasConflictWith(shift);
      });
    },
    shiftsCountIsAllowed: function shiftsCountIsAllowed() {
      var shiftsToCheck = this.getShiftsFromModel();
      var employeeSchedule = this.employeeSchedule;
      return !employeeSchedule.canAdd(shiftsToCheck);
    },
    actions: {
      validateAndSave: function validateAndSave() {
        var selectedDays = this.get('selectedDays');

        if (!selectedDays || selectedDays.length < 1) {
          return this.flashMessagesHelper.pushMessage('At least one day should be selected', 'negative');
        }

        if (this.hasConflicts()) {
          return this.flashMessagesHelper.pushMessage('Some shifts have conflict with existing ones', 'negative');
        }

        if (this.shiftsCountIsAllowed()) {
          return this.flashMessagesHelper.pushMessage('Can not create more shifts', 'negative');
        }

        this.send('save');
        return false;
      },
      afterSaveHook: function afterSaveHook(sourceShift) {
        var _this = this;

        this.flashMessagesHelper.clearMessages();
        var clones = this.get('datesToClone').map(function (date) {
          return sourceShift.cloneWithDate(date);
        });
        var savingPromises = clones.map(function (clone) {
          return clone.save().catch(function (e) {
            return e;
          });
        });
        var employeeSchedule = this.employeeSchedule;
        return Ember.RSVP.Promise.all(savingPromises).then(function (attempts) {
          var savedShifts = attempts.filter(function (s) {
            return s.id;
          });
          employeeSchedule.update(sourceShift).add(savedShifts);

          if (savedShifts.length !== clones.length) {
            throw new Error(JSON.stringify({
              errors: [{
                message: 'Some shifts have not been saved due conflicts'
              }]
            }));
          }

          _this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
        });
      },
      delete: function _delete() {
        var _this2 = this;

        var shift = this.get('model.shift');
        var employeeSchedule = this.employeeSchedule;
        this.set('isDeleting', true);
        shift.destroyRecord().then(function () {
          return employeeSchedule.remove(shift);
        }).then(function () {
          return _this2.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
        }).finally(function () {
          return _this2.set('isDeleting', false);
        });
      },
      clear: function clear() {
        this.get('model.shift').rollbackAttributes();
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
      }
    }
  });

  _exports.default = _default;
});