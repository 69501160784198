define("lh4/routes/authenticated/epro/menu/departments/edit", ["exports", "lh4/routes/general-edit-route", "ember-uuid"], function (_exports, _generalEditRoute, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    dynamicPricing: Ember.inject.service('dynamic-pricing'),
    intradayPeriod: Ember.inject.service('intraday-period'),
    locationSettings: Ember.inject.service('echo-pro/settings'),
    modelName: 'department',
    // overriding hasDirtyChangesets function set by ChangesetTransitionRouteMixin
    // for custom isDirty check before closing modal
    // because we have unusual situation nested changesets
    hasDirtyChangesets: function hasDirtyChangesets() {
      var changeset = this.controller.changeset;
      return !!(changeset.isDirty || changeset.dynamicPricingChangesets.some(function (childChangeset) {
        return childChangeset.isDirty;
      }) || changeset.availabilityChangeset.isDirty || changeset.availabilityChangeset.scheduleChangesets.some(function (childChangeset) {
        return childChangeset.isDirty;
      }));
    },
    model: function model(_ref) {
      var departmentId = _ref.departmentId;
      var _this$location$model = this.location.model,
          location = _this$location$model.id,
          corpId = _this$location$model.corpId;
      return Ember.RSVP.hash({
        department: this.store.findRecord('echo-pro/department', departmentId),
        startOfDay: this.locationSettings.getEoDSetting(location),
        items: this.modelFor('authenticated.epro.menu.departments').items,
        courses: this.modelFor('authenticated.epro.menu.departments').courses,
        stylesheets: this.isEproColorPickerFeatureFlagEnabled() ? this.store.findAll('echo-pro/stylesheet') : null,
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: location
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref2) {
            var guid = _ref2.guid,
                revenueCenterName = _ref2.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        dynamicPrices: this.store.query('echo-pro/availability-price/dynamic-price', {
          location: location,
          limit: 100,
          order: 'isActive',
          sort: 'desc'
        }),
        dayPartOptions: this.intradayPeriod.getDayPartsData(this.controller, location),
        intradayPeriodOptions: [],
        isMenuManagedByCorporate: corpId && this.corporation.getMenuManagedStatus(location)
      });
    },
    afterModel: function afterModel(model) {
      model.dayPartOptions.dayParts.intradayPeriods.forEach(function (_ref3) {
        var periods = _ref3.periods;
        periods.forEach(function (_ref4) {
          var groupGuid = _ref4.groupGuid,
              name = _ref4.name;
          model.intradayPeriodOptions.push({
            value: groupGuid,
            name: name
          });
        });
      });
    },
    setupController: function setupController(theController, model) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [theController, model].concat(args));

      var controller = theController;
      var locationId = this.location.model.id;
      var isMenuManagedByCorporate = model.isMenuManagedByCorporate,
          department = model.department,
          dynamicPrices = model.dynamicPrices,
          startOfDay = model.startOfDay;
      controller.set('isAnyScheduleOverlapping', false);
      controller.changeset.dynamicPricingChangesets = department.dynamicPricing.map(this.buildChildChangeset);
      var availability = !department.belongsTo('availability').value() ? this.store.createRecord('echo-pro/availability-price/availability', {
        locationId: locationId,
        scheduleTitle: (0, _emberUuid.v4)(),
        isActive: true,
        timeOption: 'All Day',
        revenueCenterGuids: [],
        intradayPeriodGroupGuids: [],
        schedules: [],
        overrideDates: [],
        isDateOverrideEnabled: false
      }) : department.availability.content;
      controller.changeset.availabilityChangeset = this.buildChildChangeset(availability);
      controller.changeset.availabilityChangeset.scheduleChangesets = availability.schedules.map(this.buildChildChangeset);
      var adjustedTimeOptions = this.dynamicPricing.validateCustomScheduleTimeOptions(availability.schedules, startOfDay, isMenuManagedByCorporate, Ember.set);
      var activeDynamicPrice = dynamicPrices.find(function (x) {
        return x.isActive;
      });
      controller.setProperties(_objectSpread(_objectSpread({}, adjustedTimeOptions), {}, {
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        },
        firstDynamicPriceGuid: activeDynamicPrice === null || activeDynamicPrice === void 0 ? void 0 : activeDynamicPrice.guid
      }));
    }
  });

  _exports.default = _default;
});