define("lh4/helpers/tab-value-format", ["exports", "lh4/helpers/usd-format"], function (_exports, _usdFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tabValueFormat = tabValueFormat;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Converts tab component's value
   * @param {Object} tab - tab component
   * @returns {Object} - Tab's value depending on it's presence and type
   */
  function tabValueFormat(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        tab = _ref2[0];

    if (!(tab !== null && tab !== void 0 && tab.value) && !(tab !== null && tab !== void 0 && tab.value) !== 0) {
      return (tab === null || tab === void 0 ? void 0 : tab.dataType) === 'numeric' ? '0' : '-';
    }

    if (tab.dataType === 'money') {
      var currencyValue = (0, _usdFormat.usdFormat)([parseFloat(tab.value)]);
      var unit = tab.value.charAt(tab.value.length - 1);
      var value = /[a-zA-Z]/.test(unit) ? "".concat(currencyValue).concat(unit) : currencyValue;
      return value;
    }

    return tab.value;
  }

  var _default = Ember.Helper.helper(tabValueFormat);

  _exports.default = _default;
});