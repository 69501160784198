define("lh4/components/dashboard-widgets/summary-data-card/data", ["exports", "moment", "lh4/mixins/dashboard-widget-mixin", "ember-changeset", "lh4/helpers/usd-number-format", "lh4/enums/ip-date-ranges"], function (_exports, _moment, _dashboardWidgetMixin, _emberChangeset, _usdNumberFormat, _ipDateRanges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TABS = {
    NET_TOTAL: 'Net Total',
    GROSS_TOTAL: 'Gross Total',
    TRANSACTIONS: 'Transactions'
  }; // we're getting data that includes tomorrow with a value so it's being charted.
  // this removes that data from the series as well as all the other future dates.
  // other future dates were being returned as NaN, but we'll remove to be thorough.

  var processDataSeriesForFutureDates = function processDataSeriesForFutureDates(date, previousSeries, currentSeries, selectedRange) {
    var today = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : (0, _moment.default)();
    var seriesDate = (0, _moment.default)(date).format(_moment.default.HTML5_FMT.DATE);
    var response = {
      date: seriesDate
    };
    var nextPeriod = null;

    switch (selectedRange) {
      case 'last-week':
        nextPeriod = (0, _moment.default)(date).add(1, 'week');
      // falls through

      case 'last-month':
        nextPeriod || (nextPeriod = (0, _moment.default)(date).add(1, 'month'));
        response.currentSeries = parseFloat(currentSeries);

        if (today.isAfter(nextPeriod)) {
          response.previousSeries = parseFloat(previousSeries);
        }

        break;
      // this-week and this-month function the same. using as default

      default:
        response.previousSeries = parseFloat(previousSeries);

        if (today.isAfter(seriesDate)) {
          response.currentSeries = parseFloat(currentSeries);
        }

        break;
    }

    return response;
  }; // Zero transactions count means no settlement at all for the day.
  // Customer does not want to see these as zeroes if they're past last
  // settlement so anull them.


  var anullNonSettlementData = function anullNonSettlementData(data, selectedRange) {
    var copy = JSON.parse(JSON.stringify(data));
    var thisWeekOrMonth = selectedRange === _ipDateRanges.default.THIS_WEEK.VALUE || selectedRange === _ipDateRanges.default.THIS_MONTH.VALUE;

    for (var i = copy.transactions.length - 1; i >= 0; i -= 1) {
      var series = thisWeekOrMonth ? copy.transactions[i].currentSeries : copy.transactions[i].previousSeries;
      var propertyName = thisWeekOrMonth ? 'currentSeries' : 'previousSeries';

      if (series && series !== 0) {
        break;
      }

      copy.transactions[i][propertyName] = null;
      copy.net[i][propertyName] = null;
      copy.gross[i][propertyName] = null;
    }

    return copy;
  };

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    router: Ember.inject.service(),
    sessionStorage: Ember.inject.service('session-storage'),
    media: Ember.inject.service(),
    widgetSlug: 'summary-data-card',
    classNames: ['summary-data-card', 'table-widget'],
    transactionsData: Ember.computed('data', function transactionsData() {
      var _this = this;

      var today = (0, _moment.default)();

      var _anullNonSettlementDa = anullNonSettlementData(this.get('data'), this.selectedRange),
          transactions = _anullNonSettlementDa.transactions;

      return transactions.map(function (_ref) {
        var date = _ref.date,
            previousSeries = _ref.previousSeries,
            currentSeries = _ref.currentSeries;
        return processDataSeriesForFutureDates(date, previousSeries, currentSeries, _this.selectedRange, today);
      });
    }),
    netData: Ember.computed('data', function netData() {
      var _this2 = this;

      var today = (0, _moment.default)();

      var _anullNonSettlementDa2 = anullNonSettlementData(this.get('data'), this.selectedRange),
          net = _anullNonSettlementDa2.net;

      return net.map(function (_ref2) {
        var date = _ref2.date,
            previousSeries = _ref2.previousSeries,
            currentSeries = _ref2.currentSeries;
        return processDataSeriesForFutureDates(date, previousSeries, currentSeries, _this2.selectedRange, today);
      });
    }),
    grossData: Ember.computed('data', function grossData() {
      var _this3 = this;

      var today = (0, _moment.default)();

      var _anullNonSettlementDa3 = anullNonSettlementData(this.get('data'), this.selectedRange),
          gross = _anullNonSettlementDa3.gross;

      return gross.map(function (_ref3) {
        var date = _ref3.date,
            previousSeries = _ref3.previousSeries,
            currentSeries = _ref3.currentSeries;
        return processDataSeriesForFutureDates(date, previousSeries, currentSeries, _this3.selectedRange, today);
      });
    }),
    rangeOptions: [_ipDateRanges.default.THIS_WEEK, _ipDateRanges.default.LAST_WEEK, _ipDateRanges.default.THIS_MONTH, _ipDateRanges.default.LAST_MONTH],
    selectedRange: Ember.computed(function selectedRange() {
      var sessionStorageData = this.sessionStorage.get(this.widgetSlug);
      return sessionStorageData ? sessionStorageData === null || sessionStorageData === void 0 ? void 0 : sessionStorageData.range : _ipDateRanges.default.THIS_WEEK.VALUE;
    }),
    activeTab: Ember.computed(function activeTab() {
      var sessionStorageData = this.sessionStorage.get(this.widgetSlug);
      var sessionTab = this.tabs.find(function (tab) {
        return tab.name === (sessionStorageData === null || sessionStorageData === void 0 ? void 0 : sessionStorageData.activeTab);
      });
      return sessionTab !== null && sessionTab !== void 0 ? sessionTab : this.tabs[0];
    }),
    isMonthSelected: Ember.computed('selectedRange', function isMonthSelected() {
      return this.selectedRange === _ipDateRanges.default.THIS_MONTH.VALUE || this.selectedRange === _ipDateRanges.default.LAST_MONTH.VALUE;
    }),
    tabs: Ember.computed('data', function tabs() {
      var formattingOptions = {
        notation: 'compact',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      };
      return [{
        name: TABS.NET_TOTAL,
        value: this.media.isMobile ? (0, _usdNumberFormat.usdNumberFormat)([this.get('data.totals.net'), false, formattingOptions]) : this.get('data.totals.net'),
        dataType: 'money'
      }, {
        name: TABS.GROSS_TOTAL,
        value: this.media.isMobile ? (0, _usdNumberFormat.usdNumberFormat)([this.get('data.totals.gross'), false, formattingOptions]) : this.get('data.totals.gross'),
        dataType: 'money'
      }, {
        name: TABS.TRANSACTIONS,
        value: this.media.isMobile ? (0, _usdNumberFormat.usdNumberFormat)([this.get('data.totals.transactions'), false, formattingOptions]) : this.get('data.totals.transactions'),
        dataType: 'numeric'
      }];
    }),
    onGraphClick: function onGraphClick() {// Disabling temporarily due to timezones discrepancies which causes
      // data inconsistencies for given date range between chart data and transactions page
      // return this.router.transitionTo(redirectRoute, {
      // 	queryParams: {
      // 		start: e.item.dataContext.date,
      // 		end: e.item.dataContext.date,
      // 		status: JSON.stringify([IP_TRANSACTION_STATUS.SETTLED]),
      // 	},
      // });
    },
    chartOptions: Ember.computed('data', 'activeTab', function chartOptions() {
      var _this4 = this;

      var formattingOptions = {
        style: 'currency',
        currency: 'USD',
        notation: 'standard',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      };
      return {
        categoryField: 'date',
        dataDateFormat: _moment.default.HTML5_FMT.DATE,
        parseDates: true,
        valueAxesOptions: {
          labelFrequency: 2,
          usePrefixes: true,
          minorGridAlpha: 0,
          offset: -3,
          position: 'right',
          unit: this.activeTab.name === TABS.TRANSACTIONS ? '' : '$',
          unitPosition: 'left'
        },
        categoryAxis: {
          gridColor: '#FFFFFF',
          equalSpacing: false,
          parseDates: true,
          minPeriod: 'DD',
          autoGridCount: false,
          gridCount: 7,
          showFirstLabel: false,
          dateFormats: [{
            period: 'DD',
            format: 'DD'
          }, {
            period: 'MM',
            format: 'MMM'
          }],
          centerLabelOnFullPeriod: true
        },
        listeners: [{
          event: 'clickGraphItem',
          method: this.onGraphClick.bind(this)
        }],
        chartCursor: {
          enabled: true,
          leaveCursor: true,
          graphBulletAlpha: 1,
          cursorColor: '#009bf4'
        },
        colors: ['#246FF6', '#88B7FA'],
        plotAreaBorderAlpha: 0,
        graphs: [{
          id: 'g1',
          // HACK: balloonFunction does not have access to `this` and a balloonFunction
          // appears to take precedence over balloonText so we can use this to "pass" data
          balloonText: JSON.stringify({
            tab: this.activeTab.name,
            range: this.rangeOptions.find(function (r) {
              return r.VALUE === _this4.selectedRange;
            })
          }),
          balloonFunction: function balloonFunction(graphDataItem, amGraph) {
            var _JSON$parse = JSON.parse(amGraph.balloonText),
                tab = _JSON$parse.tab,
                range = _JSON$parse.range;

            var value = graphDataItem.values.value;
            var displayValue = tab === TABS.TRANSACTIONS ? value : "".concat((0, _usdNumberFormat.usdNumberFormat)([value, 'standard', formattingOptions]));
            return "<p class=\"range\">".concat(range.NAME, "</p>\n\t\t\t\t\t\t\t<p class=\"value\">").concat(displayValue, "</p>");
          },
          title: 'First',
          valueField: 'currentSeries',
          type: 'smoothedLine',
          bulletAlpha: this.isMonthSelected ? 0 : 1
        }, {
          id: 'g2',
          // HACK: balloonFunction does not have access to `this` and a balloonFunction
          // appears to take precedence over balloonText so we can use this to "pass" data
          balloonText: JSON.stringify({
            tab: this.activeTab.name,
            range: this.rangeOptions.find(function (r) {
              return r.VALUE === _this4.selectedRange;
            })
          }),
          balloonFunction: function balloonFunction(graphDataItem, amGraph) {
            var _JSON$parse2 = JSON.parse(amGraph.balloonText),
                tab = _JSON$parse2.tab,
                range = _JSON$parse2.range;

            var value = graphDataItem.values.value;
            var displayValue = tab === TABS.TRANSACTIONS ? value : "".concat((0, _usdNumberFormat.usdNumberFormat)([value, 'standard', formattingOptions]));
            var rangeLabel;

            switch (range.VALUE) {
              case 'this-week':
                rangeLabel = 'Last week';
                break;

              case 'last-week':
                rangeLabel = 'This week';
                break;

              case 'this-month':
                rangeLabel = 'Last month';
                break;

              case 'last-month':
                rangeLabel = 'This month';
                break;

              default:
                rangeLabel = '';
                break;
            }

            return "<p class=\"range\">".concat(rangeLabel, "</p>\n\t\t\t\t\t\t\t<p class=\"value\">").concat(displayValue, "</p>");
          },
          title: 'Second',
          valueField: 'previousSeries',
          dashLength: 5,
          bulletAlpha: this.isMonthSelected ? 0 : 1,
          type: 'smoothedLine'
        }]
      };
    }),
    actions: {
      onRangeSelect: function onRangeSelect(range) {
        this.setSettings(new _emberChangeset.default({
          range: range
        }));
        this.sessionStorage.update(this.widgetSlug, {
          range: range
        });
      },
      onTabChange: function onTabChange(tab) {
        this.set('activeTab', tab);
        this.sessionStorage.update(this.widgetSlug, {
          activeTab: tab.name
        });
      }
    }
  });

  _exports.default = _default;
});