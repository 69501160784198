define("lh4/routes/authenticated/loyalty/discounts/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'discount',
    location: Ember.inject.service(),
    model: function model() {
      var _this$modelFor = this.modelFor('authenticated.loyalty.discounts'),
          programs = _this$modelFor.programs;

      var discount = this.store.createRecord('loyalty/discount');

      if (programs.length === 1) {
        discount.set('loyaltyProgramGuid', programs.firstObject);
      }

      return Ember.RSVP.hash({
        discount: discount,
        programs: programs
      });
    }
  });

  _exports.default = _default;
});