define("lh4/routes/authenticated/kitchen-display-system/preparation-times/bulk-edit", ["exports", "lh4/routes/general-edit-route", "ember-changeset", "lh4/utils/validations"], function (_exports, _generalEditRoute, _emberChangeset, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    modelName: 'item',
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model() {
      return Ember.RSVP.hash({
        item: this.store.createRecord('echo-pro/menu/item-bulk', {
          locationId: this.location.model.id
        })
      });
    },
    setupController: function setupController(theController, model) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [theController, model].concat(args));

      var controller = theController;
      controller.setProperties({
        queryParams: this.paramsFor('authenticated.kitchen-display-system.preparation-times'),
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        }
      });
    },
    buildChildChangeset: function buildChildChangeset(model) {
      var _buildChangesetDepend = (0, _validations.buildChangesetDependentValidations)(model),
          validateFn = _buildChangesetDepend.validateFn,
          validationMap = _buildChangesetDepend.validationMap;

      return new _emberChangeset.default(model, validateFn, validationMap, {
        skipValidate: true
      });
    }
  });

  _exports.default = _default;
});