define("lh4/controllers/authenticated/settings/users", ["exports", "lh4/controllers/general-list-controller", "lh4/config/environment"], function (_exports, _generalListController, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    field: 'name',
    name: 'Name',
    width: 'four',
    sortable: true
  }, {
    field: 'email',
    name: 'Email',
    width: 'four',
    sortable: true
  }, {
    field: 'active',
    name: 'Status',
    width: 'three',
    sortable: false
  }, {
    field: 'actions',
    name: 'Actions',
    width: 'five',
    sortable: false
  }];

  var _default = _generalListController.default.extend({
    media: Ember.inject.service(),
    location: Ember.inject.service(),
    headers: HEADERS,
    queryParams: ['searchTerm', 'limit', 'order', 'sort', 'allStatuses', 'statuses', 'allLocations', 'locations'],
    searchTerm: undefined,
    offset: 0,
    limit: 10,
    sortBy: 'name',
    sort: 'asc',
    statuses: [],
    allStatuses: true,
    locations: [],
    allLocations: true,
    createUserClasses: Ember.computed('media.{isDesktop,isMobile,isTablet}', function createUserClasses() {
      var baseClasses = 'ui primary button';

      if (this.media.isMobile) {
        return "".concat(baseClasses, " fluid");
      }

      return baseClasses;
    }),
    // we don't have a good way to express "nothing selected" in the API for statuses or locations
    // and either of these being empty mean there would be no results regardless so we don't need
    // to make a request. to work around this, we'll hard code an empty response when the list
    // of selected options is empty.
    users: Ember.computed('model.users', 'statuses', 'locations', function getter() {
      if (this.locations.length === 0 && this.allLocations === false || this.statuses.length === 0 && this.allStatuses === false) {
        return [];
      }

      return this.model.users;
    }),
    statusBase: [{
      id: 'Active',
      name: 'Active'
    }, {
      id: 'Not Active',
      name: 'Not Active'
    }, {
      id: 'Not Registered',
      name: 'Not Registered'
    }],
    selectAllStatusesTitle: 'All Statuses',
    statusOptions: Ember.computed('statuses', function getter() {
      var _this = this;

      return this.statusBase.map(function (status) {
        return {
          id: status.id,
          name: status.name,
          isChecked: _this.allStatuses || _this.statuses.includes(status.id)
        };
      });
    }),
    selectAllLocationsTitle: 'All Locations',
    locationOptions: Ember.computed('locations', function getter() {
      var _this2 = this;

      return this.model.locations.map(function (location) {
        return {
          id: location.id,
          name: location.name,
          isChecked: _this2.allLocations || _this2.locations.includes(location.id)
        };
      });
    }),
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('searchTerm', search);
        this.set('offset', 0);
      },
      onLocationUpdated: function onLocationUpdated(locations) {
        var adjustedLocations = locations;
        var allSelected = false;

        if (locations.length === this.model.locations.length) {
          adjustedLocations = [];
          allSelected = true;
        }

        this.setProperties({
          locations: adjustedLocations,
          offset: 0,
          allLocations: allSelected
        });
      },
      onStatusUpdated: function onStatusUpdated(statuses) {
        var adjustedStatuses = statuses;
        var allSelected = false;

        if (statuses.length === this.statusBase.length) {
          adjustedStatuses = [];
          allSelected = true;
        }

        this.setProperties({
          statuses: adjustedStatuses,
          offset: 0,
          allStatuses: allSelected
        });
      },
      openCreateUserModal: function openCreateUserModal() {
        window.location.replace("".concat(_environment.default.skytabEnterpriseURL, "/settings/users/create"));
      }
    }
  });

  _exports.default = _default;
});