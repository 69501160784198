define("lh4/controllers/general-form-controller", ["exports", "lh4/utils/dash-to-camel-case", "lh4/utils/changeset"], function (_exports, _dashToCamelCase, changesetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isSaving: false,
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    location: Ember.inject.service(),
    areChildrenInvalid: false,
    booleanError: null,
    // Used as a fallback for errors that can't be handled by components or can't be considered FE errors
    // i.e. validating models one to another
    customError: null,
    serverError: null,
    serverErrorTimeoutDelay: 5000,
    serverErrorTimeoutId: null,
    // Use this field to override return point in transitionOnClose
    closeRoute: null,
    instanceName: Ember.computed(function getter() {
      var modelName = this.changeset && this.changeset.get('data.constructor.modelName');
      return modelName ? (0, _dashToCamelCase.default)(modelName.match(/([^/]+$)/)[0]) : 'instance';
    }),
    errorMessage: Ember.computed('showErrors', 'booleanError', 'customError', 'serverError', 'areChildrenInvalid', 'changeset.isInvalid', function getter() {
      if (this.showErrors && (this.changeset.get('isInvalid') || this.areChildrenInvalid)) {
        return this.booleanError || this.customError || this.intl.t('controllers:general-form-controller:error:message:default');
      }

      return this.serverError;
    }),
    isModelAndChangesetPristine: function isModelAndChangesetPristine() {
      return !this.model[this.instanceName].isDirty && this.changeset.get('isPristine');
    },
    areChildrenPristine: function areChildrenPristine(model, changeset) {
      return changesetUtils.areChildrenPristine(model, changeset);
    },
    transitionOnClose: function transitionOnClose() {
      this.transitionToRoute(this.closeRoute || this.router.currentRoute.parent.name);
    },
    clearServerError: function clearServerError() {
      if (this.serverErrorTimeoutId) {
        Ember.run.cancel(this.serverErrorTimeoutId);
      }

      this.setProperties({
        booleanError: null,
        customError: null,
        areChildrenInvalid: false,
        serverError: null,
        serverErrorTimeoutId: null
      });
    },
    closeModal: function closeModal() {
      var _this = this;

      return new Promise(function (resolveModalClose) {
        _this.set('showErrors', false);

        Ember.run.later(_this, function () {
          _this.transitionOnClose();

          resolveModalClose();
        }, 1500);
      });
    },
    closeTray: function closeTray() {
      this.setProperties({
        showErrors: false,
        shouldCloseTray: true
      });
    },
    getErrorMessage: function getErrorMessage(error, defaultMessage) {
      // add additional check if error.details is a string before accessing first element
      // see ticket LH-8461
      if (error.details) {
        if (typeof error.details === 'string') {
          return error.details;
        }

        if (error.details[0]) {
          return error.details[0];
        }
      } // display the error from validation if it exists


      if (error.validation) {
        if (typeof error.validation === 'string') {
          return error.validation;
        }

        if (error.validation[0]) {
          return error.validation[0];
        }
      }

      return error.message || defaultMessage;
    },
    actions: {
      close: function close() {
        this.transitionOnClose();
        this.setProperties({
          shouldCloseTray: false,
          isSaving: false
        });
        this.clearServerError();
      },
      create: function create() {
        var _this2 = this;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError();
        this.changeset.validate().then(function () {
          if (_this2.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this2.changeset.get('errors'));
          } // This only gets called when there's a model that has no changes AND no validations
          // attached to it. Not a very possible scenario but let's check for consistency


          if (_this2.isModelAndChangesetPristine()) {
            return _this2.closeModal();
          }

          return _this2.changeset.save().then(function () {
            return _this2.closeModal();
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (errors && errors[0]) {
              // TODO normalize how we handle these error messages
              var createError = "Something went wrong, ".concat(_this2.instanceName, " could not be created.");
              errors.forEach(function (error) {
                var errorMessage = _this2.getErrorMessage(error, createError);

                _this2.set('serverError', errorMessage);

                _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                  _this2.set('serverError', null);
                }, _this2.serverErrorTimeoutDelay));
              });
            }
          });
        }).catch(function (errors) {
          // We currently don't have a field component for checkboxes
          // Because of this there is no standard way of displaying
          // error messages for boolean fields
          // https://jira.shift4payments.com/browse/LH-8853
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this2.set('booleanError', booleanError.validation);

              _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                _this2.set('booleanError', null);
              }, _this2.serverErrorTimeoutDelay));
            }
          }
        }).finally(function () {
          return _this2.set('isSaving', false);
        });
      },
      update: function update(trackUpdateEvent) {
        var _this3 = this;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError();
        var snapshot = this.changeset.snapshot();
        this.changeset.validate().then(function () {
          if (_this3.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this3.changeset.get('errors'));
          }

          if (_this3.isModelAndChangesetPristine()) {
            _this3.closeTray();

            return Ember.RSVP.resolve();
          }

          return _this3.changeset.save().then(function () {
            if (trackUpdateEvent) {
              trackUpdateEvent();
            }

            _this3.closeTray();
          }).catch(function (_ref2) {
            var errors = _ref2.errors;

            _this3.changeset.restore(snapshot);

            _this3.changeset.data.rollbackAttributes();

            if (errors && errors[0]) {
              // TODO normalize how we handle these error messages
              var updateError = "Something went wrong, ".concat(_this3.instanceName, " could not be updated.");
              errors.forEach(function (error) {
                var errorMessage = _this3.getErrorMessage(error, updateError);

                _this3.set('serverError', errorMessage);

                _this3.set('serverErrorTimeoutId', Ember.run.later(_this3, function () {
                  _this3.set('serverError', null);
                }, _this3.serverErrorTimeoutDelay));
              });
            }
          });
        }).catch(function (errors) {
          // We currently don't have a field component for checkboxes
          // Because of this there is no standard way of displaying
          // error messages for boolean fields
          // https://jira.shift4payments.com/browse/LH-8853
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this3.set('booleanError', booleanError.validation);

              _this3.set('serverErrorTimeoutId', Ember.run.later(_this3, function () {
                _this3.set('booleanError', null);
              }, _this3.serverErrorTimeoutDelay));
            }
          }
        }).finally(function () {
          return _this3.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});