define("lh4/controllers/authenticated/internet-payments/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    can: Ember.inject.service(),

    get routeData() {
      var routeData = [];

      if (this.can.can('manage account-maintenance')) {
        routeData.push({
          name: 'authenticated.internet-payments.settings.account-maintenance',
          title: 'Account Maintenance'
        });
      }

      if (this.can.can('view business settings nav in general')) {
        routeData.push({
          name: 'authenticated.internet-payments.settings.business-settings',
          title: 'Business Settings'
        });
      }

      return routeData;
    }

  });

  _exports.default = _default;
});