define("lh4/routes/authenticated/epro/menu/modifiers/edit", ["exports", "lh4/routes/general-edit-route", "lh4/utils/menu-handle-changeset-update"], function (_exports, _generalEditRoute, _menuHandleChangesetUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    modelName: 'modifier',
    model: function model(_ref) {
      var modifierId = _ref.modifierId;
      return Ember.RSVP.hash({
        modifier: this.store.findRecord('echo-pro/modifier', modifierId),
        modifierSets: this.modelFor('authenticated.epro.menu.modifiers').modifierSets,
        printers: this.modelFor('authenticated.epro.menu.modifiers').printers,
        stylesheets: this.isEproColorPickerFeatureFlagEnabled() ? this.store.findAll('echo-pro/stylesheet') : null
      });
    },
    afterModel: function afterModel(model) {
      var attrs = [['printers', 'printerIds'], ['modifierSets', 'modifierSetIds'], ['nestedModifierSets', 'nestedModifierSetIds']];
      (0, _menuHandleChangesetUpdate.default)(attrs, model.modifier);
    }
  });

  _exports.default = _default;
});