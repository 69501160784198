define("lh4/routes/authenticated/loyalty/members/edit", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_TRANSACTIONS_PER_TRAY = 3;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'member',
    childChangesets: {
      transactions: 'addAdjustmentTransactionChangesets'
    },
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    model: function model(_ref) {
      var memberId = _ref.memberId;
      return Ember.RSVP.hash({
        MAX_TRANSACTIONS_PER_TRAY: MAX_TRANSACTIONS_PER_TRAY,
        member: this.store.findRecord('loyalty/member', memberId),
        programs: this.modelFor('authenticated.loyalty.members').programs,
        transactions: this.store.query('loyalty/transaction', {
          limit: MAX_TRANSACTIONS_PER_TRAY,
          loyaltyMemberGuid: memberId,
          order: 'createdAt',
          sort: 'DESC'
        })
      });
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
      // that doesn't exist in the loyalty discount context because they are not location dependent
    }
  });

  _exports.default = _default;
});