define("lh4/mirage/routes/solo-terminals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageSoloDevicesServer;

  function setMirageSoloDevicesServer(server) {
    server.get('/api/v2/solo/terminal', function (schema) {
      return schema.soloTerminals.all();
    });
  }
});