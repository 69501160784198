define("lh4/routes/authenticated/epro/menu/order-types/create", ["exports", "lh4/routes/general-create-route", "lh4/utils/increment-name"], function (_exports, _generalCreateRoute, _incrementName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    location: Ember.inject.service(),
    queryParams: {
      clone: {
        refreshModel: true
      }
    },
    modelName: 'orderType',
    model: function model(_ref) {
      var clone = _ref.clone;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        weekdays: this.modelFor('authenticated.epro.menu.order-types').weekdays,
        orderType: this.createOrderType(clone),
        sections: this.store.query('reservations/section', {
          location: location
        }),
        printers: this.store.query('echo-pro/printer', {
          location: location
        })
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      if (controller.clone) {
        // changing of changeset properties instead of model properties ensures thant changeset becomes dirty
        // and and confirm dialog is fired when trying to close modal after cloning even if nothing was changed
        var _this$store$peekRecor = this.store.peekRecord('echo-pro/order-type', controller.clone),
            name = _this$store$peekRecor.name,
            onlineName = _this$store$peekRecor.onlineName;

        controller.changeset.set('name', (0, _incrementName.default)(name));
        controller.changeset.set('onlineName', onlineName ? (0, _incrementName.default)(onlineName) : null);
      }

      controller.set('isSaving', false);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('clone', null);
      }
    },
    createOrderType: function createOrderType(cloneId) {
      return cloneId // always use findRecord for duplication to run trough serializer
      ? this.store.findRecord('echo-pro/order-type', cloneId).then(function (original) {
        return original.duplicateModel();
      }) : this.store.createRecord('echo-pro/order-type', {
        locationId: this.location.model.id
      });
    }
  });

  _exports.default = _default;
});