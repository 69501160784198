define("lh4/mirage/routes/launch-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageBusinessSettings;

  /* eslint require-jsdoc: "off" */
  function setMirageBusinessSettings(serverObject) {
    var server = serverObject;
    server.namespace = '/api/v1';
    var workflowId = '141ce154-a610-4ff9-a44d-2d1c994e6304';
    server.get('/launch-control/modules/:locationId/:workflowName', function (schema, _ref) {
      var locationId = _ref.params.locationId;
      return {
        modules: [{
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T14:08:57.687Z',
          moduleId: 'cc3d2762-69c7-40ba-a8b4-9e9f4a31d72a',
          workflowId: workflowId,
          moduleName: 'mod-installation-details-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {
            contacts: {
              contacts: [{
                name: 'Manager user',
                email: 'manager-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVP7ATZZ7DX0GKG4JM09FJKR',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }, {
                name: 'Admin user',
                email: 'admin-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643YPQ',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Admin'
              }, {
                name: 'Manager user2',
                email: 'manager-user-2@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643HBG',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }]
            }
          },
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '1a4ff362-9c63-4469-9854-864992c7e891',
          workflowId: workflowId,
          moduleName: 'mod-menu-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'f449e10c-914f-4c8f-ac69-c9c42ae715e7',
          workflowId: workflowId,
          moduleName: 'mod-restaurant-basics-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2396b241-dba4-4d44-944d-7cdc41bb1653',
          workflowId: workflowId,
          moduleName: 'mod-online-ordering-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2070d219-e009-469d-9b51-20bf8f2e2c11',
          workflowId: workflowId,
          moduleName: 'mod-integrations-v2',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'f76c27e5-4c09-4bda-9d44-04df9db18d6d',
          workflowId: workflowId,
          moduleName: 'mod-gift-cards-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'e2718616-1530-473d-8d0c-4f565ec1b102',
          workflowId: workflowId,
          moduleName: 'mod-loyalty-v1',
          completedAt: null,
          stepData: {
            programs: [{
              name: 'Program 1',
              isActive: true,
              locations: ['1'],
              pointName: 'Point 1'
            }],
            discounts: [{
              name: 'Discount 1',
              type: 'amount',
              value: 1099,
              isActive: true,
              pointCost: '3',
              loyaltyProgramName: 'Program 1'
            }],
            promotions: [{
              name: 'Promotion 1',
              type: 'MULTIPLIER',
              value: '2',
              endDate: '2024-05-15T05:00:00.000Z',
              endTime: '2024-05-21T21:00:00.000Z',
              isActive: true,
              startDate: '2024-05-07T05:00:00.000Z',
              startTime: '2024-05-21T13:00:00.000Z',
              isAllowedFriday: true,
              isAllowedMonday: true,
              isAllowedSunday: false,
              isAllowedTuesday: true,
              isRepeatedYearly: false,
              isAllowedSaturday: false,
              isAllowedThursday: true,
              isAllowedWednesday: true,
              loyaltyProgramName: 'Program 1'
            }]
          },
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '63575666-7db1-49c3-a982-b16df009d569',
          workflowId: workflowId,
          moduleName: 'mod-reservations-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'df9d5ef3-c755-4ae9-86d1-ca7b2fe2bfd9',
          workflowId: workflowId,
          moduleName: 'mod-skytab-bi-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '7e3dc5e9-0f8b-4a2e-93f4-c173b324f25c',
          workflowId: workflowId,
          moduleName: 'mod-website-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '9a8fdfe7-cc8d-4f02-8995-9ae74b789db9',
          workflowId: workflowId,
          moduleName: 'mod-table-layout-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-06-14T08:21:34.604Z',
          updatedAt: '2024-06-18T12:52:22.712Z',
          moduleId: '197ba11b-b337-4129-aaed-623cf070153e',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          moduleName: 'mod-employees-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-08-09T08:21:34.604Z',
          updatedAt: '2024-08-10T12:52:22.712Z',
          moduleId: '68d840ab-c5fe-4773-bf0c-2e4c78e4b578',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          moduleName: 'mod-discounts-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-08-09T08:21:34.604Z',
          updatedAt: '2024-08-10T12:52:22.712Z',
          moduleId: '68d840ab-c5fe-4773-bf0c-2e4c78e4b579',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          moduleName: 'mod-kiosk-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }]
      };
    });
    server.get('/status-control/:locationId/modules', function (schema, _ref2) {
      var locationId = _ref2.params.locationId;
      return {
        modules: [{
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T14:08:57.687Z',
          moduleId: 'cc3d2762-69c7-40ba-a8b4-9e9f4a31d72a',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-installation-details-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {
            contacts: {
              contacts: [{
                name: 'Manager user',
                email: 'manager-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVP7ATZZ7DX0GKG4JM09FJKR',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }, {
                name: 'Admin user',
                email: 'admin-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643YPQ',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Admin'
              }, {
                name: 'Manager user2',
                email: 'manager-user-2@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643HBG',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }]
            }
          },
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '1a4ff362-9c63-4469-9854-864992c7e891',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-menu-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'f449e10c-914f-4c8f-ac69-c9c42ae715e7',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-restaurant-basics-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2396b241-dba4-4d44-944d-7cdc41bb1653',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-online-ordering-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2070d219-e009-469d-9b51-20bf8f2e2c11',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-integrations-v2',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'f76c27e5-4c09-4bda-9d44-04df9db18d6d',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-gift-cards-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'e2718616-1530-473d-8d0c-4f565ec1b102',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-loyalty-v1',
          completedAt: null,
          stepData: {
            programs: [{
              name: 'Program 1',
              isActive: true,
              locations: ['1'],
              pointName: 'Point 1'
            }],
            discounts: [{
              name: 'Discount 1',
              type: 'amount',
              value: 1099,
              isActive: true,
              pointCost: '3',
              loyaltyProgramName: 'Program 1'
            }],
            promotions: [{
              name: 'Promotion 1',
              type: 'MULTIPLIER',
              value: '2',
              endDate: '2024-05-15T05:00:00.000Z',
              endTime: '2024-05-21T21:00:00.000Z',
              isActive: true,
              startDate: '2024-05-07T05:00:00.000Z',
              startTime: '2024-05-21T13:00:00.000Z',
              isAllowedFriday: true,
              isAllowedMonday: true,
              isAllowedSunday: false,
              isAllowedTuesday: true,
              isRepeatedYearly: false,
              isAllowedSaturday: false,
              isAllowedThursday: true,
              isAllowedWednesday: true,
              loyaltyProgramName: 'Program 1'
            }]
          },
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '63575666-7db1-49c3-a982-b16df009d569',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-reservations-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'df9d5ef3-c755-4ae9-86d1-ca7b2fe2bfd9',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-skytab-bi-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '7e3dc5e9-0f8b-4a2e-93f4-c173b324f25c',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-website-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '9a8fdfe7-cc8d-4f02-8995-9ae74b789db9',
          workflowId: workflowId,
          locationId: locationId,
          moduleName: 'mod-table-layout-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-06-14T08:21:34.604Z',
          updatedAt: '2024-06-18T12:52:22.712Z',
          moduleId: '197ba11b-b337-4129-aaed-623cf070153e',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          locationId: locationId,
          moduleName: 'mod-employees-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-08-09T08:21:34.604Z',
          updatedAt: '2024-08-10T12:52:22.712Z',
          moduleId: '68d840ab-c5fe-4773-bf0c-2e4c78e4b578',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          locationId: locationId,
          moduleName: 'mod-discounts-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-08-09T08:21:34.604Z',
          updatedAt: '2024-08-10T12:52:22.712Z',
          moduleId: '68d840ab-c5fe-4773-bf0c-2e4c78e4b579',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          locationId: locationId,
          moduleName: 'mod-kiosk-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }]
      };
    });
    server.get('/launch-control/merchant-order-contacts/:locationId', function () {
      return {
        contacts: [{
          contactId: '01HVP7ATZZ7DX0GKG4JM09FJKR',
          email: 'manpreet.singh@shift4.com',
          name: 'Name 1',
          phone: '8888579751',
          textMessageEnabled: true,
          isActive: true
        }, {
          contactId: '01HVP7ATC9D0S6QWQ0WHH86Z5K',
          email: 'abc@shift4.com',
          name: 'Name 2',
          phone: '8888579755',
          textMessageEnabled: true,
          isActive: true,
          createUserLogin: true
        }]
      };
    });
    server.get('/launch-control/shipping-address/:locationId', function () {
      return {
        streetAddress: '2202 N Irving Street',
        city: 'Allentown',
        state: 'PA',
        zip: '18109'
      };
    });
    server.get('/launch-control/go-live-date/:locationId', function () {
      return {
        GoLiveDate: '2024-04-22T12:31:40.348Z'
      };
    });
    server.get('/launch-control/csm/:locationId', function () {
      return {
        cms: {
          Phone: 'N/A',
          Email: 'test.test@shift4.com',
          FullName: 'test',
          LName: 'test',
          FName: 'test'
        }
      };
    });
    server.get('/launch-control/delivery-platforms/:locationId', function () {
      return {
        uberEats: {
          installed: true
        },
        doorDash: {
          installed: true
        }
      };
    });
    server.get('/launch-control/order-tracker/:locationId', function () {
      return {
        shipped: false,
        OrderTrackerTimeline: {
          hardwareSoftwareDeployed: {
            subHeading: []
          },
          installation: {
            isCurrent: true,
            isComplete: false
          }
        }
      };
    });
    server.get('/launch-control/order-tracker-status/:locationId', function () {
      return {
        taskSystemId: '01HXF2NT1YQY9DKJ49YSHATH03',
        orderId: '368112',
        menuProgrammingComplete: false,
        installationScheduled: false,
        installationComplete: false,
        equipmentShipped: false
      };
    });
    server.get('/status-control/:id/module/:moduleName', function (schema, _ref3) {
      var _ref3$params = _ref3.params,
          id = _ref3$params.id,
          moduleName = _ref3$params.moduleName;

      if (moduleName === 'mod-installation-details-v1') {
        return {
          module: [{
            createdAt: '2024-04-19T12:31:40.348Z',
            updatedAt: '2024-04-19T14:08:57.687Z',
            moduleId: 'cc3d2762-69c7-40ba-a8b4-9e9f4a31d72a',
            workflowId: workflowId,
            moduleName: 'mod-installation-details-v1',
            completedAt: null,
            stepData: {
              contacts: {
                contacts: [{
                  name: 'Manager user',
                  email: 'manager-user@shift4.com',
                  phone: '+37062216843',
                  isActive: true,
                  contactId: '01HVP7ATZZ7DX0GKG4JM09FJKR',
                  createUserLogin: true,
                  textMessageEnabled: true,
                  role: 'Manager'
                }, {
                  name: 'Admin user',
                  email: 'admin-user@shift4.com',
                  phone: '+37062216843',
                  isActive: true,
                  contactId: '01HVSV0DXVRBV8KMZE93643YPQ',
                  createUserLogin: true,
                  textMessageEnabled: true,
                  role: 'Admin'
                }, {
                  name: 'Manager user2',
                  email: 'manager-user-2@shift4.com',
                  phone: '+37062216843',
                  isActive: true,
                  contactId: '01HVSV0DXVRBV8KMZE93643HBG',
                  createUserLogin: true,
                  textMessageEnabled: true,
                  role: 'Manager'
                }]
              }
            },
            isSkipped: false,
            status: 'AVAILABLE'
          }]
        };
      }

      return {
        module: [{
          moduleId: '232-232-232-232',
          moduleName: moduleName,
          locationId: id,
          completedAt: null,
          stepData: {}
        }]
      };
    });
    server.get('/status-control/:locationId/feature', function () {
      return {
        features: [{
          data: {
            contacts: {
              contacts: [{
                name: 'Manager user',
                email: 'manager-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVP7ATZZ7DX0GKG4JM09FJKR',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }, {
                name: 'Admin user',
                email: 'admin-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643YPQ',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Admin'
              }, {
                name: 'Manager user2',
                email: 'manager-user-2@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643HBG',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }]
            }
          },
          isLimitedNavigationEnabled: false,
          moduleName: 'mod-installation-details-v1',
          status: 'AVAILABLE',
          subtitle: 'Confirm your site contacts, shipping address and go-live date'
        }, {
          moduleName: 'mod-restaurant-basics-v1',
          status: 'AVAILABLE',
          data: {
            LogoUpload: {
              updatedAt: '2024-11-28T11:42:36.184Z'
            },
            LocationDetails: {
              email: 'test.test@shift4.com',
              updatedAt: '2024-11-28T15:43:02.571Z',
              phoneNumber: '(800) 201-0532',
              restaurantName: 'Launch control location'
            }
          },
          subtitle: 'Confirm Restaurant Basics',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-menu-v1',
          status: 'AVAILABLE',
          data: {},
          subtitle: 'Our team will build your menu at no cost',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-table-layout-v1',
          status: 'AVAILABLE',
          data: {},
          subtitle: 'Set up your floor plan',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-gift-cards-v1',
          status: 'AVAILABLE',
          data: {},
          subtitle: 'Sell gift cards in-store or online',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-loyalty-v1',
          status: 'AVAILABLE',
          data: {},
          subtitle: 'Use SkyTab Loyalty to engage and reward customers',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-kiosk-v1',
          status: 'AVAILABLE',
          data: {},
          subtitle: 'Serve faster and increase sales with our modern kiosk solution',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-website-v1',
          status: 'AVAILABLE',
          data: {},
          subtitle: 'Launch a website in minutes',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-skytab-bi-v1',
          status: 'AVAILABLE',
          data: {},
          subtitle: 'Access enhanced sales and labor reporting',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-online-ordering-v1',
          status: 'AVAILABLE',
          data: {
            OrderTypes: {
              takeout: true,
              delivery: false,
              timezone: 'America/New_York',
              updatedAt: '2024-11-14T13:48:14.433Z',
              onlineName: 'test'
            },
            DeliverySettings: {
              asap: true,
              sameDay: false,
              updatedAt: '2024-11-14T13:48:22.562Z',
              specialRequest: false,
              onlineGiftCards: false,
              flexibleScheduling: false
            },
            OrderTypeSettings: {
              takeout: {
                averagePrepTime: '1'
              },
              delivery: {
                surcharge: null,
                orderMinimum: null,
                deliveryRange: 'distance',
                averageDeliveryTime: null
              },
              updatedAt: '2024-11-14T13:48:19.265Z'
            }
          },
          subtitle: 'SkyTab Online Ordering is easy to use and directly integrated into your POS',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-employees-v1',
          status: 'AVAILABLE',
          data: {
            jobs: {
              records: [{
                guid: '993d6ae4-e5a1-425d-a072-4451c56dbbce',
                name: 'Bartender',
                isBlind: false,
                payRate: 1,
                isActive: true,
                loginType: 1,
                _isDefault: true,
                locationId: 1,
                operatesAs: 'Restricted',
                defaultMenuType: 'Departments',
                overtimePayRate: 1.5,
                usePersonalTill: true,
                isGridViewEnabled: true,
                isHostModeEnabled: true,
                isHoldItemsEnabled: true,
                ticketPaymentOperationType: 1,
                isCompleteAllTicketsEnabled: true,
                isDisplayShiftReportEnabled: true,
                isDeclareTipsAtClockoutEnabled: true
              }, {
                guid: 'e70da030-0361-4769-9463-b7c510f5e8b8',
                name: 'Busser',
                payRate: 1,
                isActive: true,
                loginType: 3,
                _isDefault: true,
                locationId: 1,
                defaultMenuType: 'Departments',
                overtimePayRate: 1.5,
                isFastPayEnabled: false,
                isGridViewEnabled: false,
                isHostModeEnabled: false,
                isHoldItemsEnabled: false,
                ticketPaymentOperationType: 2,
                isDeclareTipsAtClockoutEnabled: true,
                isIgnoreRequiredModifiersEnabled: false
              }, {
                guid: '911ceedb-bdc0-491e-8ef1-9547f62e87f8',
                name: 'Cashier',
                payRate: 1,
                isActive: true,
                loginType: 0,
                _isDefault: true,
                locationId: 1,
                defaultMenuType: 'Departments',
                overtimePayRate: 1.5,
                isHostModeEnabled: false,
                isHoldItemsEnabled: false,
                ticketPaymentOperationType: 0,
                canAccessRecentlyClosedTicket: true,
                isIgnoreRequiredModifiersEnabled: false,
                shouldAutoPrintDeliveryInformationOnTransfer: true
              }, {
                guid: 'd36427aa-d08e-41dc-aa5c-f51a6f658d3b',
                name: 'Cook',
                isBlind: true,
                payRate: 1,
                isActive: true,
                loginType: 3,
                _isDefault: true,
                locationId: 1,
                defaultMenuType: 'Departments',
                overtimePayRate: 1.5,
                isFastPayEnabled: false,
                isGridViewEnabled: false,
                isHostModeEnabled: false,
                isHoldItemsEnabled: false,
                ticketPaymentOperationType: 2,
                isIgnoreRequiredModifiersEnabled: false
              }, {
                guid: '1921f81c-c78d-44c4-b969-8934abc578a2',
                name: 'Delivery',
                payRate: 1,
                isActive: true,
                loginType: 1,
                _isDefault: true,
                locationId: 1,
                defaultMenuType: 'Departments',
                overtimePayRate: 1.5,
                usePersonalTill: true,
                isFastPayEnabled: false,
                isHostModeEnabled: false,
                isHoldItemsEnabled: false,
                isAccessAllTicketsEnabled: true,
                ticketPaymentOperationType: 0,
                canAccessRecentlyClosedTicket: true,
                isTransferTicketOnScanEnabled: true,
                isDeclareTipsAtClockoutEnabled: true,
                isIgnoreRequiredModifiersEnabled: false,
                shouldAutoPrintDeliveryInformationOnTransfer: true
              }, {
                guid: '28d5f0d4-24e0-42c4-b484-a36b3416d5a4',
                name: 'Host',
                isBlind: false,
                payRate: 1,
                isActive: true,
                loginType: 1,
                _isDefault: true,
                locationId: 1,
                operatesAs: 'Restricted',
                defaultMenuType: 'Departments',
                overtimePayRate: 1.5,
                usePersonalTill: true,
                isGridViewEnabled: true,
                isHostModeEnabled: true,
                isHoldItemsEnabled: true,
                ticketPaymentOperationType: 0,
                isCompleteAllTicketsEnabled: true,
                isDisplayShiftReportEnabled: true,
                isDeclareTipsAtClockoutEnabled: true
              }, {
                guid: 'fcf1f2d0-08fb-4706-865c-713c5821d2d1',
                name: 'Owner',
                payRate: 1,
                isActive: true,
                loginType: 1,
                _isDefault: true,
                locationId: 1,
                defaultMenuType: 'Departments',
                overtimePayRate: 1.5,
                isFastPayEnabled: false,
                isAccessAllTicketsEnabled: true,
                ticketPaymentOperationType: 1,
                canSetHouseAccountThreshold: true,
                canAccessRecentlyClosedTicket: true,
                isIgnoreRequiredModifiersEnabled: false
              }, {
                guid: '55f57687-c77b-45d3-863e-27a59afdeb54',
                name: 'Server',
                isBlind: false,
                payRate: 1,
                isActive: true,
                loginType: 1,
                _isDefault: true,
                locationId: 1,
                operatesAs: 'Restricted',
                defaultMenuType: 'Departments',
                overtimePayRate: 1.5,
                usePersonalTill: true,
                isGridViewEnabled: true,
                isHostModeEnabled: true,
                isHoldItemsEnabled: true,
                ticketPaymentOperationType: 2,
                isCompleteAllTicketsEnabled: true,
                isDisplayShiftReportEnabled: true,
                isDeclareTipsAtClockoutEnabled: true
              }, {
                guid: 'd72be137-bf3f-4087-a7ea-527ae80826ea',
                name: 'Back of House',
                payRate: 1,
                isActive: true,
                loginType: 3,
                _isDefault: true,
                locationId: 1,
                operatesAs: 'Restricted',
                defaultMenuType: 'Departments',
                overtimePayRate: 1.5,
                ticketPaymentOperationType: 2,
                isDisplayShiftReportEnabled: true,
                isDeclareTipsAtClockoutEnabled: true
              }, {
                code: null,
                guid: '11b88dff-9978-41da-94ac-e79e8a8fe8d0',
                name: 'test',
                isBlind: false,
                payRate: '0.00',
                isActive: true,
                isTipped: false,
                loginType: 0,
                locationId: 1,
                description: null,
                isTrackEnabled: false,
                defaultMenuType: 'Departments',
                overtimePayRate: 0,
                isFastPayEnabled: false,
                usesPersonalTill: false,
                isGridViewEnabled: false,
                isHostModeEnabled: false,
                isHoldItemsEnabled: false,
                defaultOrderTypeGuid: null,
                canViewHiddenDepartments: false,
                isAccessAllTicketsEnabled: false,
                isManualWeightEntryEnabled: false,
                ticketPaymentOperationType: 0,
                canSetHouseAccountThreshold: false,
                isDisplayShiftReportEnabled: false,
                canAccessRecentlyClosedTicket: false,
                isTransferTicketOnScanEnabled: false,
                isDeclareTipsAtClockoutEnabled: false,
                isIgnoreRequiredModifiersEnabled: false,
                shouldAutoPrintDeliveryInformationOnTransfer: false
              }],
              updatedAt: '2024-11-26T09:48:23.944Z',
              removedDefaultRecords: []
            },
            employees: {
              records: [{
                guid: '079bdde4-8b1d-40d2-a3f0-47d31f876bbc',
                name: 'Owner',
                _dates: [],
                jobIds: ['fcf1f2d0-08fb-4706-865c-713c5821d2d1'],
                _phones: [],
                isActive: true,
                loginPin: '1111',
                _payRates: [{
                  guid: '6ab20223-d227-41bf-a2f2-a06c33e032ab',
                  jobGuid: 'fcf1f2d0-08fb-4706-865c-713c5821d2d1',
                  payRate: 1,
                  employee: '079bdde4-8b1d-40d2-a3f0-47d31f876bbc',
                  isVoided: false,
                  locationId: 1,
                  employeeGuid: null
                }],
                _isDefault: true,
                locationId: 1
              }, {
                guid: 'e0a8418d-40df-4460-bbf7-18373f590556',
                name: 'Example Cashier',
                _dates: [],
                jobIds: ['911ceedb-bdc0-491e-8ef1-9547f62e87f8'],
                _phones: [],
                isActive: true,
                loginPin: '2222',
                _payRates: [{
                  guid: '37c40b7b-40c5-4ffd-9fae-e15aa53082f3',
                  jobGuid: '911ceedb-bdc0-491e-8ef1-9547f62e87f8',
                  payRate: 1,
                  employee: 'e0a8418d-40df-4460-bbf7-18373f590556',
                  isVoided: false,
                  locationId: 1,
                  employeeGuid: null
                }],
                _isDefault: true,
                locationId: 1
              }, {
                guid: 'becdee26-9351-4a6a-9760-43f45b186440',
                name: 'Example Server',
                _dates: [],
                jobIds: ['55f57687-c77b-45d3-863e-27a59afdeb54'],
                _phones: [],
                isActive: true,
                loginPin: '3333',
                _payRates: [{
                  guid: '4ab6b9d0-814e-4d6c-897c-7d142e7959fc',
                  jobGuid: '55f57687-c77b-45d3-863e-27a59afdeb54',
                  payRate: 1,
                  employee: 'becdee26-9351-4a6a-9760-43f45b186440',
                  isVoided: false,
                  locationId: 1,
                  employeeGuid: null
                }],
                _isDefault: true,
                locationId: 1
              }, {
                guid: '3b7282a8-b996-4136-8628-57686edef1cb',
                name: 'Example Bartender',
                _dates: [],
                jobIds: ['993d6ae4-e5a1-425d-a072-4451c56dbbce'],
                _phones: [],
                isActive: true,
                loginPin: '4444',
                _payRates: [{
                  guid: 'b69d5af7-4cdc-4ddd-8642-eda713e5381b',
                  jobGuid: '993d6ae4-e5a1-425d-a072-4451c56dbbce',
                  payRate: 1,
                  employee: '3b7282a8-b996-4136-8628-57686edef1cb',
                  isVoided: false,
                  locationId: 1,
                  employeeGuid: null
                }],
                _isDefault: true,
                locationId: 1
              }],
              updatedAt: '2024-11-12T14:56:30.020Z',
              removedDefaultRecords: []
            }
          },
          subtitle: 'Manage your employees in one place',
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-integrations-v2',
          status: 'AVAILABLE',
          data: {},
          subtitle: "Let's finish setting up your Online Ordering",
          isLimitedNavigationEnabled: false
        }, {
          moduleName: 'mod-discounts-v1',
          status: 'AVAILABLE',
          data: {},
          subtitle: 'Manage your discounts and gratuities in one place',
          isLimitedNavigationEnabled: false
        }, {
          data: {},
          subtitle: 'Test',
          isLimitedNavigationEnabled: false,
          moduleName: 'mod-reservations-v1',
          status: 'AVAILABLE'
        }]
      };
    });
  }
});