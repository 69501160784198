define("lh4/abilities/launch-control", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service(),
    permissions: Ember.inject.service('permissions'),
    canViewLaunchControl: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', 'location.model', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.LAUNCH_CONTROL] && this.hasProduct('echo-pro') && this.location.isUS;
    }),
    canViewSkytabBi: (0, _abilityMixin.computedAbility)(function getter() {
      return this.permissions.hasPermission(_permissions.default.REPORTING.REPORTS) || this.permissions.hasPermission(_permissions.default.REPORTING.ACCESS_REPORTING) || this.permissions.hasPermission(_permissions.default.REPORTING.CREATE_OWN_SUBSCRIPTIONS) || this.permissions.hasPermission(_permissions.default.REPORTING.MANAGE_USERS_SUBSCRIPTIONS);
    })
  });

  _exports.default = _default;
});