define("lh4/routes/authenticated/epro/menu/items/edit", ["exports", "lh4/routes/general-edit-route", "ember-uuid", "lh4/utils/menu-handle-changeset-update"], function (_exports, _generalEditRoute, _emberUuid, _menuHandleChangesetUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    dynamicPricing: Ember.inject.service('dynamic-pricing'),
    intradayPeriod: Ember.inject.service('intraday-period'),
    locationSettings: Ember.inject.service('echo-pro/settings'),
    modelName: 'item',
    // overriding hasDirtyChangesets function set by ChangesetTransitionRouteMixin
    // for custom isDirty check before closing modal
    // because we have unusual situation nested changesets
    hasDirtyChangesets: function hasDirtyChangesets() {
      var changeset = this.controller.changeset;
      return !!(changeset.isDirty || changeset.dynamicPricingChangesets.some(function (childChangeset) {
        return childChangeset.isDirty;
      }) || changeset.availabilityChangeset.isDirty || changeset.availabilityChangeset.scheduleChangesets.some(function (childChangeset) {
        return childChangeset.isDirty;
      }));
    },
    model: function model(_ref) {
      var itemId = _ref.itemId;
      var _this$location$model = this.location.model,
          location = _this$location$model.id,
          corpId = _this$location$model.corpId;
      return Ember.RSVP.hash({
        item: this.store.findRecord('echo-pro/item', itemId),
        startOfDay: this.locationSettings.getEoDSetting(location),
        departments: this.modelFor('authenticated.epro.menu.items').departments,
        modifierSets: this.modelFor('authenticated.epro.menu.items').modifierSets,
        taxes: this.modelFor('authenticated.epro.menu.items').taxes,
        revenueClasses: this.modelFor('authenticated.epro.menu.items').revenueClasses,
        courses: this.modelFor('authenticated.epro.menu.items').courses,
        printers: this.modelFor('authenticated.epro.menu.items').printers,
        itemTags: this.modelFor('authenticated.epro.menu.items').itemTags,
        priceScheduleGroups: this.modelFor('authenticated.epro.menu.items').priceScheduleGroups,
        stylesheets: this.isEproColorPickerFeatureFlagEnabled() ? this.store.findAll('echo-pro/stylesheet') : null,
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: location
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref2) {
            var guid = _ref2.guid,
                revenueCenterName = _ref2.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        dynamicPrices: this.store.query('echo-pro/availability-price/dynamic-price', {
          location: location,
          limit: 100,
          order: 'isActive',
          sort: 'desc'
        }),
        dayPartOptions: this.intradayPeriod.getDayPartsData(this.controller, location),
        intradayPeriodOptions: [],
        isMenuManagedByCorporate: corpId && this.corporation.getMenuManagedStatus(location)
      });
    },
    afterModel: function afterModel(model) {
      model.dayPartOptions.dayParts.intradayPeriods.forEach(function (_ref3) {
        var periods = _ref3.periods;
        periods.forEach(function (_ref4) {
          var groupGuid = _ref4.groupGuid,
              name = _ref4.name;
          model.intradayPeriodOptions.push({
            value: groupGuid,
            name: name
          });
        });
      }); // Workaround to fix mentioned attributes being left in outdated state after bulk editing menu items (LH-17935)

      var attrs = [['taxes', 'taxIds'], ['printers', 'printerIds'], ['modifierSets', 'modifierSetIds']];
      (0, _menuHandleChangesetUpdate.default)(attrs, model.item);
    },
    setupController: function setupController(theController, model) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [theController, model].concat(args));

      var controller = theController;
      var locationId = this.location.model.id;
      var isMenuManagedByCorporate = model.isMenuManagedByCorporate,
          item = model.item,
          dynamicPrices = model.dynamicPrices,
          priceScheduleGroups = model.priceScheduleGroups,
          startOfDay = model.startOfDay;
      controller.set('isAnyScheduleOverlapping', false);
      controller.changeset.priceScheduleMapChangesets = item.itemPriceScheduleMaps.map(this.buildChildChangeset);
      controller.changeset.dynamicPricingChangesets = item.dynamicPricing.map(this.buildChildChangeset);
      var availability = !item.belongsTo('availability').value() ? this.store.createRecord('echo-pro/availability-price/availability', {
        locationId: locationId,
        scheduleTitle: (0, _emberUuid.v4)(),
        isActive: true,
        timeOption: 'All Day',
        revenueCenterGuids: [],
        intradayPeriodGroupGuids: [],
        schedules: [],
        overrideDates: [],
        isDateOverrideEnabled: false
      }) : item.availability.content;
      controller.changeset.availabilityChangeset = this.buildChildChangeset(availability);
      controller.changeset.availabilityChangeset.scheduleChangesets = availability.schedules.map(this.buildChildChangeset);
      var adjustedTimeOptions = this.dynamicPricing.validateCustomScheduleTimeOptions(availability.schedules, startOfDay, isMenuManagedByCorporate, Ember.set);
      var activePriceScheduleGroup = priceScheduleGroups.find(function (x) {
        return x.isActive;
      });
      var activeDynamicPrice = dynamicPrices.find(function (x) {
        return x.isActive;
      });
      controller.setProperties(_objectSpread(_objectSpread({}, adjustedTimeOptions), {}, {
        shouldDeleteImage: false,
        shouldSaveImage: false,
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        },
        selectedPriceScheduleGroupGuid: activePriceScheduleGroup === null || activePriceScheduleGroup === void 0 ? void 0 : activePriceScheduleGroup.guid,
        priceScheduleOptions: this.getPriceScheduleOptions(activePriceScheduleGroup, controller.changeset),
        selectedPriceGroupChanged: function selectedPriceGroupChanged(data) {
          return _this.selectedPriceGroupChanged(data);
        },
        firstDynamicPriceGuid: activeDynamicPrice === null || activeDynamicPrice === void 0 ? void 0 : activeDynamicPrice.guid
      }));
    },
    selectedPriceGroupChanged: function selectedPriceGroupChanged(selectedPriceScheduleGroupGuid) {
      var _this2 = this;

      this.set('selectedPriceScheduleGroupGuid', selectedPriceScheduleGroupGuid);
      var activeGroup = this.currentModel.priceScheduleGroups.find(function (x) {
        return x.guid === _this2.selectedPriceScheduleGroupGuid;
      });
      var priceScheduleOptions = this.getPriceScheduleOptions(activeGroup, this.controller.changeset);
      this.controller.setProperties({
        selectedPriceScheduleGroupGuid: selectedPriceScheduleGroupGuid,
        priceScheduleOptions: priceScheduleOptions
      });
    },
    getPriceScheduleOptions: function getPriceScheduleOptions(activeGroup, changeset) {
      var _this3 = this;

      var itemGuid = changeset.get('guid');
      return activeGroup === null || activeGroup === void 0 ? void 0 : activeGroup.priceSchedules.sortBy('name').map(function (priceSchedule) {
        var name = priceSchedule.name,
            priceScheduleGuid = priceSchedule.guid;
        var itemPriceScheduleMap = changeset.priceScheduleMapChangesets.findBy('priceScheduleGuid', priceScheduleGuid);

        if (!itemPriceScheduleMap) {
          var itemPriceScheduleMapModel = _this3.store.createRecord('echo-pro/item-price-schedule-map', {
            itemGuid: itemGuid,
            priceScheduleGuid: priceScheduleGuid
          });

          itemPriceScheduleMap = _this3.buildChildChangeset(itemPriceScheduleMapModel);
          changeset.priceScheduleMapChangesets.pushObject(itemPriceScheduleMap);
        }

        itemPriceScheduleMap.set('name', name);
        itemPriceScheduleMap.set('ranges', [priceSchedule]);
        return itemPriceScheduleMap;
      });
    }
  });

  _exports.default = _default;
});