define("lh4/controllers/authenticated/epro/menu/price-groups/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    actions: {
      update: function update() {
        var _this = this;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          showErrors: true,
          isSaving: true
        });
        this.clearServerError();
        var snapshot = this.changeset.snapshot();
        this.changeset.validate().then(function () {
          if (_this.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this.changeset.get('errors'));
          }

          if (_this.isModelAndChangesetPristine()) {
            _this.closeTray();

            return Ember.RSVP.resolve();
          }

          return _this.changeset.save().then(function () {
            _this.closeTray();
          }).catch(function (_ref) {
            var errors = _ref.errors;

            _this.changeset.restore(snapshot);

            _this.changeset.data.rollbackAttributes();

            if (errors && errors[0]) {
              // TODO normalize how we handle these error messages
              var updateError = "Something went wrong, ".concat(_this.instanceName, " could not be updated.");
              errors.forEach(function (error) {
                var errorMessage = error.details && error.details[0] || error.message || updateError;

                _this.set('serverError', errorMessage);

                _this.set('serverErrorTimeoutId', Ember.run.later(_this, function () {
                  _this.set('serverError', null);
                }, _this.serverErrorTimeoutDelay));
              });
            }
          });
        }).catch(function () {// do nothing, validation already handled
        }).finally(function () {
          return _this.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});