define("lh4/routes/authenticated/epro/menu/order-types/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    modelName: 'orderType',
    model: function model(_ref) {
      var orderTypeId = _ref.orderTypeId;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        weekdays: this.modelFor('authenticated.epro.menu.order-types').weekdays,
        orderType: this.store.findRecord('echo-pro/order-type', orderTypeId),
        sections: this.store.query('reservations/section', {
          location: location
        }),
        printers: this.store.query('echo-pro/printer', {
          location: location
        })
      });
    }
  });

  _exports.default = _default;
});