define("lh4/constants/weekdays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ABBREVIATIONS = _exports.DAY_OF_WEEK_ORDER = void 0;
  var DAY_OF_WEEK_ORDER = Object.freeze({
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6
  });
  _exports.DAY_OF_WEEK_ORDER = DAY_OF_WEEK_ORDER;
  var ABBREVIATIONS = Object.freeze({
    SUNDAY: 'Sun',
    MONDAY: 'Mon',
    TUESDAY: 'Tue',
    WEDNESDAY: 'Wed',
    THURSDAY: 'Thu',
    FRIDAY: 'Fri',
    SATURDAY: 'Sat'
  });
  _exports.ABBREVIATIONS = ABBREVIATIONS;
});