define("lh4/controllers/authenticated/locations/location/echo-pro/employee-schedule/template/apply", ["exports", "lh4/mixins/edit-controller-mixin"], function (_exports, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    employeeSchedule: Ember.inject.service(),
    location: Ember.inject.service(),
    normalize: function normalize(response) {
      var _this = this;

      var normalized = response.shifts.map(function (json) {
        return _this.store.normalize('echo-pro/employee-shift', json);
      });
      var shifts = normalized.map(function (shift) {
        return _this.store.push(shift);
      });
      return shifts.map(function (shift, index) {
        return shift.normalizeRelations(response.shifts[index]);
      });
    },
    actions: {
      apply: function apply() {
        var _this2 = this;

        var _this$get = this.get('model.range'),
            from = _this$get.from,
            to = _this$get.to;

        var data = {
          method: 'POST',
          data: JSON.stringify({
            templateId: this.get('selected'),
            locationId: this.get('model.location.id'),
            start: from.toISOString(),
            end: to.toISOString()
          })
        };
        this.set('isLoading', true);
        this.ajax.request('/api/v1/echo-pro/schedule-templates/create-shifts', data).then(function (response) {
          var shifts = _this2.normalize(response);

          _this2.employeeSchedule.add(shifts);

          _this2.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
        }).catch(function (e) {
          return _this2.flashMessagesHelper.pushMessages(_this2.responseMessages.getResponseErrors(e));
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      },
      clear: function clear() {
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
      }
    }
  });

  _exports.default = _default;
});