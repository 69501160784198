define("lh4/routes/authenticated/epro/menu/price-schedule-groups/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    modelName: 'priceScheduleGroup',
    childChangesets: {
      priceSchedules: 'priceScheduleChangesets'
    },
    model: function model(_ref) {
      var priceScheduleGroupId = _ref.priceScheduleGroupId;
      return Ember.RSVP.hash({
        priceScheduleGroup: this.store.findRecord('echo-pro/price-schedule-group', priceScheduleGroupId)
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      controller.set('isAnyScheduleOverlapping', false);
    }
  });

  _exports.default = _default;
});